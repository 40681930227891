import { request } from "../request";

export default {
    // 左上图获取数据
    industrialChain() {
        return request({
            method: "POST",
            isLoding: true,
            url: "/trum/industrialChain"
        });
    },
    //左下图获取数据
    serviceDomain(){
        return request({
            method: "POST",
            isLoding: true,
            url: "/trum/serviceDomain"
        });
    },
    //中间
    areaEquipNum(){
        return request({
            method: "POST",
            isLoding: true,
            url: "/trum/areaEquipNum"
        });
    },
    //右上
    categories(){
        return request({
            method: "POST",
            isLoding: true,
            url: "/trum/categoriesStatistics"
        });
    },
    //设备原值分析
    equipValue(){
        return request({
            method: "POST",
            isLoding: true,
            url: "/trum/equipValue"
        });
    },
    //右下
    unitNature(){
        return request({
            method: "POST",
            isLoding: true,
            url: "/trum/unitNature"
        });
    },
    //热点
    hotspot(){
        return request({
            method: "POST",
            isLoding: true,
            url: "/trum/hotspot"
        });
    },
    //设备详情
    areaNumList(data){
        return request({
            data,
            method: "POST",
            isLoding: true,
            url: "/trum/areaNumList"
        });
    }
}
