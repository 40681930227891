import {request} from "../request";

export default {
    login(data) {
        return request({
            url: '/prod-api/auth/login',
            method: 'post',
            data
        })
    }, getCodeImg() {
        return request({
            url: '/prod-api/code',
            method: 'get',
            timeout: 20000
        })
    },logout() {
        return request({
            url: '/prod-api/auth/logout',
            method: 'delete'
        })
    },register(data) {
      return request({
        url: '/prod-api/auth/register',
        method: 'post',
        data: data
      })
    }

}
