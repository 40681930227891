<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'app',
  components: {},
  // beforeCreate() {
  //   new DevicePixelRatio().init();
	// },
  mounted () {
    // document.getElementById('app').style.width = this.detectZoom() + '%';
    // window.addEventListener('resize', () => { //监听浏览器窗口大小改变
    //   //浏览器变化执行动作
    //   document.getElementById('app').style.width = this.detectZoom() + '%'
    // });
  },
  methods:{}
}
</script>

<style>

</style>
