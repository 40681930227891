<template src="./screen.html"></template>
<style scoped lang="scss-loader" src="./screen.scss"></style>
<script>
import mixin from "./mixin";
import progressContain from '../components/progress.vue';
import mapContain from '../components/3dmap.vue';
import pagination from '../components/pagination.vue';
export default {
    mixins: [mixin],
    components:{progressContain,mapContain,pagination},
    data(){
        return{
            ratio:window.devicePixelRatio,
            clientWidth:document.body.clientWidth,
            chainTotal:{},//左上图总数
            serviceData:{
                home:{},
                platformTotal:{},
                currentTotal:{},
                replyTotal:{},
            },//左下图，
            mapContentData:{
                '武进其他':{
                    percent:'',
                    sumCount:0,
                    sumOrival:0,
                    validCount:0,
                    validOrival:0
                },
                '经开区':{
                    percent:'',
                    sumCount:0,
                    sumOrival:0,
                    validCount:0,
                    validOrival:0
                },
                '科教城':{
                    percent:'',
                    sumCount:0,
                    sumOrival:0,
                    validCount:0,
                    validOrival:0
                },
                '武高新':{
                    percent:'',
                    sumCount:0,
                    sumOrival:0,
                    validCount:0,
                    validOrival:0
                },
                '西太湖':{
                    percent:'',
                    sumCount:0,
                    sumOrival:0,
                    validCount:0,
                    validOrival:0
                },
                '武进区':{
                    percent:'',
                    sumCount:0,
                    sumOrival:0,
                    validCount:0,
                    validOrival:0
                },
                '金坛区':{
                    percent:'',
                    sumCount:0,
                    sumOrival:0,
                    validCount:0,
                    validOrival:0
                },
                '新北区':{
                    percent:'',
                    sumCount:0,
                    sumOrival:0,
                    validCount:0,
                    validOrival:0
                },
                '天宁区':{
                    percent:'',
                    sumCount:0,
                    sumOrival:0,
                    validCount:0,
                    validOrival:0
                },
                '钟楼区':{
                    percent:'',
                    sumCount:0,
                    sumOrival:0,
                    validCount:0,
                    validOrival:0
                },
                '溧阳市':{
                    percent:'',
                    sumCount:0,
                    sumOrival:0,
                    validCount:0,
                    validOrival:0
                },
                '市属':{
                    percent:'',
                    sumCount:0,
                    sumOrival:0,
                    validCount:0,
                    validOrival:0
                },
            },//地图关联上方数据处理
            mapData:{},//地图数据 传入组件
            currentArea:'市属',//地图当前选中区域
            classifyNum:{},//右上
            equipValue:{},
            equipNumList:[],
            unitNature:{},
            visitObj:{},
            deviceObj:{},
            showMap:false,
            dialogVisible:false,
            deviceVisible:false,
            mapIdData:{},
            deviceTitle:'',
            tableData:[],
            queryParams:{
                pageNum:1,
                pageSize:10
            },
            total:0,
            loading: true,
        }
    },
    watch:{
        mapData:{
            deep:true,
            immediate:true,
            handler(val){
                console.log(val,'hhhhhhhhhhhhh');
            }
        }
    },
    beforeCreate(){
        document.getElementsByTagName('body')[0].style.zoom = 1
    },
    created(){
        this.getIndustrialChain();
        this.getServiceDomain();
        this.getAreaEquipNum();
        this.getCategories();
        this.getEquipValue();
        this.getUnitNature();
        this.getHotspot();
    },
    mounted(){
        window.addEventListener('resize', () => { //监听浏览器窗口大小改变
            document.getElementsByTagName('body')[0].style.zoom = 1
        });
    },
    methods:{
        //数字添加千位分隔符
        handleNumbers(number){
            if(!number) return 0;
            var res = number.toString().replace(/\d+/, function(n){ // 先提取整数部分
                return n.replace(/(\d)(?=(\d{3})+$)/g,function($1){
                    return $1+",";
                });
            })
            return res;
        },
        showDialog(){
            this.dialogVisible = true;
        },
        getIndustrialChain(){//左上
            this.$api.screen.industrialChain().then(res=>{
                if(res.data){
                    this.chainTotal = res.data.total;
                    res.data.chain.forEach((element,index) => {
                        if(index < 5){
                            this.option3.yAxis.data.push(element.dict_label);
                            this.option3.series[0].data.push(
                                {value:element.counts,percent:element.percent}
                            );
                        }
                        this.option4.xAxis.data.push(element.dict_label);
                        this.option4.series[0].data.push(
                            {value:element.counts,percent:element.percent}
                        );
                    });
                    console.log(document.getElementById("left-top").offsetHeight);
                    if(document.getElementById("left-top").offsetHeight < 330){
                        this.option3.grid.height = '62%';
                        this.option3.grid.bottom = '15%';
                    };
                    if(document.getElementById("left-top").offsetHeight > 340){
                        this.option3.grid.height = '73%';
                        this.option3.grid.top = '10%';
                    };
                }
            })
        },
        getServiceDomain(){//左下
            this.$api.screen.serviceDomain().then(res=>{
                if(res.data){
                    this.serviceData = res.data;
                    this.serviceData.top.forEach(element => {
                        this.option1.legend.data.push(
                            {name:element.dict_label,icon:'circle'}
                        );
                        this.option1.series[0].data.push(
                            {name:element.dict_label,value:element.counts,percent:element.percent}
                        );
                    })
                    this.serviceData.all.forEach(element => {
                        this.option2.legend.data.push(
                            {name:element.dict_label,icon:'circle'}
                        );
                        this.option2.series[0].data.push(
                            {name:element.dict_label,value:element.counts,percent:element.percent}
                        );
                    });
                    if(document.getElementsByClassName("echarts-container")[0].offsetHeight < 270){
                        document.getElementsByClassName("echarts-container")[0].style.height = '28vh';
                        this.option2.legend.top = "46%";
                        document.getElementById('notice-left-1').style['marginTop'] = '-1.1vh'
                        document.getElementById('notice-left-2').style['marginTop'] = '-1.1vh'
                    }
                }
            })
        },
        getAreaEquipNum(){
            this.$api.screen.areaEquipNum().then(res=>{
                if(res.data){
                    // res.data.month.forEach(element => {
                    //     this.option4.xAxis.data.push(element.month);
                    //     this.option4.series[0].data.push(element.record)
                    // });
                    //地图数据处理
                    // this.mapData['武进区'] = 0;
                    res.data.areaEquip.forEach(element => {
                        this.mapData[element.deptName] = element.sumCount;
                        this.mapContentData[element.deptName] = element;
                        this.mapIdData[element.deptName] = element.dept_id;
                    })
                    this.showMap = true
                }
            });
        },
        showAreaData(data){
            this.currentArea = data;
        },
        showAreaDetail(){
            this.deviceTitle = this.currentArea + '仪器汇总';
            this.queryParams.pageNum = 1;
            this.getList();
        },
        getList(){
            let id = '';
            if(this.currentArea == '武进区'){
                id = this.mapIdData['武进其他'] + ',' + this.mapIdData['经开区'] + ',' + this.mapIdData['武高新'] + ',' + this.mapIdData['科教城'] + ',' + this.mapIdData['西太湖'] + ''
            }else{
                id = this.mapIdData[this.currentArea] + '';
            }
            this.deviceVisible = true;
            this.loading = true;
            this.tableData = [];
            this.$api.screen.areaNumList({...this.queryParams,areaId:id}).then(res => {
                this.tableData = res.rows;
                this.total = res.total;
                this.loading = false;
            })
        },
        //右上
        getCategories(){
            this.$api.screen.categories().then(res=>{
                if(res.data){
                    this.classifyNum = res.data.classifyNum;
                    // this.option5.graphic[1].style.text = this.handleNumbers(this.classifyNum.visit_sum);
                    // this.option6.graphic[1].style.text = this.handleNumbers(this.classifyNum.device_sum);

                    //访问
                    // res.data.visitStatistics.forEach(element => {
                    //     // this.option5.series[0].data.push(
                    //     //     {name:element.dict_name,value:element.record,percent:element.percent}
                    //     // );
                    //     this.option5.series[0].data.push(
                    //         {name:element.dict_name,value:element.record,percent:element.percent}
                    //     );
                    //     this.option5.yAxis.data.push(element.dict_name)
                    // })
                    //设备
                    res.data.deviceStatistics.forEach(element => {
                        this.option5.legend.data.push(element.dict_name);
                        this.option5.series[0].data.push(
                            {name:element.dict_name,value:element.record,percent:element.percent}
                        );
                    })
                    this.visitObj = {
                        name:res.data.visitStatistics[0].dict_name,
                        percent:res.data.visitStatistics[0].percent
                    };
                    this.deviceObj = {
                        name:res.data.deviceStatistics[0].dict_name,
                        percent:res.data.deviceStatistics[0].percent
                    };
                }
            })
        },
        //设备原值分析
        getEquipValue(){
            this.$api.screen.equipValue().then(res=>{
                if(res.data){
                    this.equipValue = res.data.equipValue;
                    let colors = ['#F4EE4F','#373FAE','#1CBEFA']
                    res.data.equipNum.forEach((item,index) => {
                        this.equipNumList.push({...item,bgColor:colors[index]})
                    })
                }
            })
        },
        //右下
        getUnitNature(){
            this.$api.screen.unitNature().then(res=>{
                if(res.data){
                    let name = '';
                    res.data.natureName.forEach((item,index)=>{
                        name += index == 0 ? item.dict_label : ('、' + item.dict_label);
                    });
                    this.unitNature.name = name;
                    this.unitNature.total = res.data.total.total;
                    console.log(this.unitNature);

                    //图表数据处理
                    let arr = []
                    res.data.percentNum.forEach(item =>{
                        arr.push(item.total)
                        this.option7.radar.indicator.push(
                            {name:item.dict_label,max:item.max}
                        )
                    })
                    this.option7.series[0].data.push(
                        {value:arr,name:'单位性质'}
                    )
                    
                    console.log(this.option7,'ffffffffffffffffffff');
                    //{value:item.total,name:item.dict_label,percent:item.percent}
                }
            })
        },
        //热点
        getHotspot(){
            this.$api.screen.hotspot().then(res=>{
                if(res.data){
                    res.data.hotspotCompany.forEach((item,index)=>{
                        // this.option8.series[0].data.push(
                        //     {name:item.companyName,value:item.record}
                        // );
                        this.option8.series[index].data[0].value = item.record;
                        this.option8.series[index].data[0].name = item.companyName;
                        this.option8.series[index].data[1].value = item.other;
                        this.option8.legend.data.push(
                            {name:item.companyName,icon:'circle'}
                        );
                    });
                    res.data.hotspotDevice.forEach((item,index)=>{
                        this.option9.series[index].data[0].value = item.record;
                        this.option9.series[index].data[0].name = item.deviceName;
                        this.option9.series[index].data[1].value = item.other;
                        this.option9.legend.data.push(
                            {name:item.deviceName,icon:'circle'}
                        );
                    });
                }
            })
        },
        back(){
            this.$router.push('/index')
        }
    }
}
</script>