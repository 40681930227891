<template src="./index.html"></template>
<style scoped lang="scss-loader" src="./index.scss"></style>
<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
import * as swiperAni from '../../static/swiper.animate1.0.3.min.js';
import headerLogo from '../components/header.vue';
import footerIndex from '../components/footer.vue';
import DevicePixelRatio from '../../devicePixelRatio';
export default {
    components: {
        swiper,
        swiperSlide,
        headerLogo,
        footerIndex
    },
    data(){
        // let that = this;
        return{
            show:true,
            swiperOption: {
                loop: true,
                autoplay: {
                    delay: 3000,
                },
                on: {
                    init: function(){
                        swiperAni.swiperAnimateCache(this);
                        swiperAni.swiperAnimate(this);
                    },
                    slideChangeTransitionStart:function(){},
                    slideChangeTransitionEnd:function(){},
                    slideChange:function(){
                        swiperAni.swiperAnimate(this)
                    },
                }
            },
            summaryData:{}
        }
    },
    beforeCreate() {
        new DevicePixelRatio().init();
	},
    computed: {
        swiper() {
            return this.$refs.mySwiper.swiper;
        }
    },
    mounted() {
        // current swiper instance
        // 然后你就可以使用当前上下文内的swiper对象去做你想做的事了
        console.log("this is current swiper instance object", this.swiper);
        // this.swiper.slideTo(3, 1000, false);
        this.getSummary();
    },
    methods:{
        goToPage(url){
            this.$router.push(url)
        },
        getSummary(){
            this.$api.news.summary().then(res => {
                this.summaryData = res.data
            })
        }
    }
}
</script>