<template>
    <div>
        <header-logo></header-logo>
        <div class="banner-sub banner-content">
            <div class="container">
                <div class="inner">
                <div class="content-title">
                    <h1>{{detail.name}}</h1>
                    <p style="text-indent:0"> <span class="weizhi"> </span> <span>时间：{{detail.createtime ? detail.createtime.slice(0,10) : ''}}</span></p>
                </div>
                </div>
            </div>
        </div>
        <div class="section-sub">
            <div class="back-list">
                <!-- <img src="../assets/img/back-list-b.png">
                <span>返回列表</span> -->
                <span>当前位置：</span>
                <el-breadcrumb separator="/">
                    <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
                    <el-breadcrumb-item :to="{ path: '/newsList',query:{type:this.$route.query.type} }">政策法规</el-breadcrumb-item>
                    <el-breadcrumb-item>详情</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
            
            <div class="container clearfix"> 
                <!--侧边栏-->
                <ul class="v-lanmu">
                    <li v-for="(item,index) in rightNewsList" :key="index">
                        <a @click="goToDetail(item.id)">
                            <strong>{{item.name && item.name.length > 17 ? item.name.slice(0,16)+'...' : item.name}}</strong>{{item.dict_label}}
                        </a>
                    </li>
                </ul>
                <div class="v-main">
                    <div class="content-main" v-loading="loading">
                        <div class="content-body" v-html="detail.content">
                                <!-- {{detail.content}} -->
                        </div>
                        <div class="fileList">
                            <div style="margin-top:5px" v-for="(item,index) in fileList" :key="index">
                                附件{{index+1}}：
                                <a target="_blank" :href="item.url">{{item.name}}</a>
                            </div>
                        </div>
                        <!--上一篇下一篇-->
                        <ul class="content-exp">
                            <li>
                                上一篇：
                                <a @click="goToDetail(detail.upId)" :class="{'active':detail.upId}">
                                    {{detail.upName}}
                                </a>
                            </li>
                            <li>
                                下一篇：
                                <a @click="goToDetail(detail.downId)" :class="{'active':detail.downId}">
                                    {{detail.downName}}
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <footer-index></footer-index>
    </div>
</template>
<script>
import headerLogo from './components/header.vue';
import footerIndex from './components/footer.vue';
import DevicePixelRatio from '../devicePixelRatio';
export default {
    components:{headerLogo,footerIndex},
    data(){
        return{
            detailId:'',
            rightNewsList:[],
            detail:{},
            fileList:[],
            loading:true,
        }
    },
    beforeCreate() {
        new DevicePixelRatio().init();
	},
    created(){
        this.getRightList();
    },
    watch: {
        $route: { // 监听路由的参数变化
            handler () { // 数据变化更新数据
                console.log(this.$route.matched);
                this.detailId = this.$route.query.id;
                this.getDetail();
            },
            immediate: true // 立即执行
        }
    },
    methods:{
        back(type){
            if(type){
                this.$router.push({
                    path:'/newsList',
                    query:{
                        type:this.$route.query.type
                    }
                })
            }else{
                this.$router.push('/index')
            }
        },
        getDetail(){
            if(this.detailId){
                this.$api.news.getDetail(this.detailId).then(res=>{
                    this.detail = res.data;
                    this.loading = false;
                    if(this.detail.content){
                        this.detail.content = decodeURIComponent(this.detail.content)
                    }
                    if(this.detail.attachment){
                        let list = this.detail.attachment.split(',');
                        list.forEach(item => {
                            if (item.lastIndexOf("/") > -1) {
                                this.fileList.push(
                                    {
                                        name:item.slice(item.lastIndexOf("/") + 1).toLowerCase(),
                                        url:item
                                    }
                                )
                            }
                        })
                    }else{
                        this.fileList = [];
                    }
                })
            }
        },
        getRightList(){
            this.$api.news.getRightList().then(res=>{
                if(res.rows){
                    this.rightNewsList = res.rows
                }
            })
        },
        goToDetail(id){
            if(!id){
                return
            }
            this.$router.push({
                path:'/newsDetail',
                query:{
                    id:id,
                    type:this.$route.query.type
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
    * {
        margin: 0;
        padding: 0;
    }
    a {
        color: #333;
        text-decoration: none;
    }
    .banner-sub{
        height: 410px;
        background: no-repeat center / cover;
        color: #fff;
        text-align: center;
        font-size: 44px;
        text-shadow: 0 2px 2px #333333;
        background-image: url('../assets/img/banner.jpg');

        .container {
            display: table;
            height: 100%;
            padding: 0 15px;
            width: 1200px;
            margin: 0 auto;
            position: relative;
        }
    }

    .banner-content {
        .inner{
            vertical-align: bottom;

            .content-title {
                text-align: left;
                margin-bottom: 40px;
                position: absolute;
                bottom: 0;

                h1 {
                    font-size: 38px;
                    margin: 0;
                }

                p {
                    font-size: 0;
                    margin: 20px 0 0;

                    span {
                        display: inline-block;
                        vertical-align: middle;
                        font-size: 14px;
                        margin-right: 30px;
                    }

                    .weizhi {
                        float: right;
                        font-size: 14px;
                    }
                }
            }
        }
        
    }

    .section-sub{
        background-color: #f8f8f8;
        padding: 20px 0 80px 0;

        .container{
            padding: 0 15px;
            width: 1200px;
            margin: 0 auto;
        }

        .back-list{
            padding: 0 15px;
            width: 1200px;
            margin: 0 auto;
            margin-bottom: 10px;

            span{
                font-size: 14px;
                position: relative;
                top: 0px;
                transform: translateY(15%);
            }
        }
    }

    .back{
        position: fixed;
        right: 30px;
        width: 60px;
        height: 60px;
        background-color: #2c2c2c66;
        border-radius: 30px;
        img{
            position: relative;
            top: 0px;
            transform: translateY(45%);
        }
        text-align: center;
    }

    // .back-home{
    //     bottom: 20px;
    // }

    // .back-list{
    //     bottom: 100px;
    // }

    ul {
        margin: 0;
        list-style: none;
        padding: 0;
    }

    .v-lanmu {
        float: right;
        width: 160px;

        li {
            margin-bottom: 10px;
            font-size: 14px;

            a {
                display: block;
                width: 90px;
                padding: 20px;
                border: 1px solid #ddd;
                background-color: #fff;
                transition: all 0.5s;
            }

            a:after {
                display: block;
                content: '';
                width: 20px;
                height: 2px;
                background-color: #68a3d8;
                margin-top: 10px;
                transition: all 0.5s;
            }

            a:hover {
                background-color: #68a3d8;
                color: #fff;
                border-color: #68a3d8;
                width: 118px;
                cursor: pointer;
            }

            a:hover:after {
                background-color: #fff;
                cursor: pointer;
            }

            strong {
                display: block;
                line-height: 1.1;
                margin-bottom: 5px;
            }
        }
    }

    .v-main {
        margin-right: 200px;

        .content-main {
            background-color: #fff;
            padding: 30px;

            .content-body{
                line-height: 1.8;
                min-height: 600px;
            }

            .content-exp {
                border-top: 1px dashed #ccc;
                padding-top: 20px;
                overflow: hidden;
                margin-top: 20px;

                li {
                    float: left;
                    width: 50%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }

                li:last-child {
                    text-align: right;
                }
            }
        }
    }

    .active:hover{
        color: #68a3d8;
        cursor: pointer;
    }
    .fileList{
        a:hover{
            text-decoration: underline;
            color: #68a3d8;
            cursor: pointer;
        }
    }
    ::v-deep .el-breadcrumb {
        display: inline-block;
    }
</style>