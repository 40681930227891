<template>
  <div class="login">
    <div class="pagelogo"><img style="width:100%" src="../assets/images/login.png"></div>
    <!-- 登录 -->
    <el-form ref="loginForm" :model="loginForm" :rules="loginRules" class="login-form" v-if="loginOrRegister">
      <!-- style="position: fixed;left:33.5%;top:25%" -->
      <h3 class="title">登录</h3>
      <el-form-item prop="username">
        <el-input v-model="loginForm.username" type="text" auto-complete="off" placeholder="账号">
          <svg-icon slot="prefix" icon-class="user" class="el-input__icon input-icon" />
        </el-input>
      </el-form-item>
      <el-form-item prop="password">
        <el-input
          v-model="loginForm.password"
          type="password"
          auto-complete="off"
          placeholder="密码"
          @keyup.enter.native="handleLogin"
        >
          <svg-icon slot="prefix" icon-class="password" class="el-input__icon input-icon" />
        </el-input>
      </el-form-item>
      <el-form-item prop="code" v-if="captchaOnOff">
        <el-input
          v-model="loginForm.code"
          auto-complete="off"
          placeholder="验证码"
          style="width: 63%"
          @keyup.enter.native="handleLogin"
        >
          <svg-icon slot="prefix" icon-class="validCode" class="el-input__icon input-icon" />
        </el-input>
        <div class="login-code">
          <img :src="codeUrl" @click="getCode" class="login-code-img"/>
        </div>
      </el-form-item>
      <!-- <el-checkbox v-model="loginForm.rememberMe" style="margin:0px 0px 25px 0px;">记住密码</el-checkbox> -->
      <el-form-item style="width:100%;">
        <el-button
          :loading="loading"
          size="medium"
          type="primary"
          style="width:100%;"
          @click.native.prevent="handleLogin"
        >
          <span v-if="!loading">登 录</span>
          <span v-else>登 录 中...</span>
        </el-button>
        <div style="float: right;" v-if="register">
          <!-- <router-link class="link-type" :to="'/register'">立即注册</router-link> -->
          <span class="link-type" @click="toRegister()">立即注册</span>
        </div>
      </el-form-item>
    </el-form>
    <!-- 注册 -->
    <el-form ref="registerForm" :model="registerForm" :rules="registerRules" class="register-form" v-else>
      <!-- style="position: fixed;left:33.5%;top:25%" -->
      <h3 class="title">注册</h3>  
      <el-form-item style="width:100%; margin-top: -20px;margin-bottom: 0px;">
        <div style="float: left;" v-if="register" >
            <!-- <router-link class="link-type" :to="'/register'">立即注册</router-link> -->
            已有帐号?
            <span class="link-type" @click="toLogin()">登录</span>
        </div>
      </el-form-item>
      <el-form-item prop="userType">
              <treeselect
                v-model="registerForm.userType"
                :disable-branch-nodes="true"
                :defaultExpandLevel="Infinity"
                :options="subUserType"
                :normalizer="normalizer"
                placeholder="请选择用户类型"
                @input="change($event, 'userType')"
              />
        </el-form-item>
      <div v-if="selectUserType">
        <el-form-item prop="companyName" >
          <el-input v-model="registerForm.companyName" type="text" auto-complete="off" placeholder="企业名称">
            <svg-icon slot="prefix" icon-class="user" class="el-input__icon input-icon" />
          </el-input>
        </el-form-item>
        <el-form-item prop="unicode">
          <el-input
            v-model="registerForm.unicode"
            type="text"
            auto-complete="off"
            placeholder="社会统一信用代码"
            @keyup.enter.native="handleRegister"
          >
            <svg-icon slot="prefix" icon-class="password" class="el-input__icon input-icon" />
          </el-input>
        </el-form-item>
        <el-form-item prop="registerPassword">
          <el-input
            v-model="registerForm.registerPassword"
            type="password"
            auto-complete="off"
            placeholder="密码"
            @keyup.enter.native="handleRegister"
          >
            <svg-icon slot="prefix" icon-class="password" class="el-input__icon input-icon" />
          </el-input>
        </el-form-item>
        <el-form-item prop="confirmPassword">
          <el-input
            v-model="registerForm.confirmPassword"
            type="password"
            auto-complete="off"
            placeholder="确认密码"
            @keyup.enter.native="handleRegister"
          >
            <svg-icon slot="prefix" icon-class="password" class="el-input__icon input-icon" />
          </el-input>
        </el-form-item>
        <el-form-item prop="areaId">
                <treeselect
                  v-model="registerForm.areaId"
                  :disable-branch-nodes="true"
                  :defaultExpandLevel="Infinity"
                  :options="deptOptions"
                  :normalizer="normalizer"
                  placeholder="请选择所在区域"
                  @input="change($event, 'areaId')"
                />
          </el-form-item>
      </div> 
      <div v-else>
        <el-form-item prop="username" >
          <el-input v-model="registerForm.username" type="text" auto-complete="off" placeholder="账户">
            <svg-icon slot="prefix" icon-class="user" class="el-input__icon input-icon" />
          </el-input>
        </el-form-item>
        <el-form-item prop="nickName">
          <el-input
            v-model="registerForm.nickName"
            type="text"
            auto-complete="off"
            placeholder="所在单位"
            @keyup.enter.native="handleRegister"
          >
            <svg-icon slot="prefix" icon-class="password" class="el-input__icon input-icon" />
          </el-input>
        </el-form-item>
        <el-form-item prop="phonenumber" >
          <el-input v-model="registerForm.phonenumber" type="text" auto-complete="off" placeholder="手机号">
            <svg-icon slot="prefix" icon-class="user" class="el-input__icon input-icon" />
          </el-input>
        </el-form-item>
        <el-form-item prop="email">
          <el-input
            v-model="registerForm.email"
            type="text"
            auto-complete="off"
            placeholder="邮箱"
            @keyup.enter.native="handleRegister"
          >
            <svg-icon slot="prefix" icon-class="password" class="el-input__icon input-icon" />
          </el-input>
        </el-form-item>
        <el-form-item prop="registerPassword">
          <el-input
            v-model="registerForm.registerPassword"
            type="password"
            auto-complete="off"
            placeholder="密码"
            @keyup.enter.native="handleRegister"
          >
            <svg-icon slot="prefix" icon-class="password" class="el-input__icon input-icon" />
          </el-input>
        </el-form-item>
        <el-form-item prop="confirmPassword">
          <el-input
            v-model="registerForm.confirmPassword"
            type="password"
            auto-complete="off"
            placeholder="确认密码"
            @keyup.enter.native="handleRegister"
          >
            <svg-icon slot="prefix" icon-class="password" class="el-input__icon input-icon" />
          </el-input>
        </el-form-item>
      </div> 
      <!-- <el-form-item prop="code" v-if="captchaOnOff">
        <el-input
          v-model="loginForm.code"
          auto-complete="off"
          placeholder="验证码"
          style="width: 63%"
          @keyup.enter.native="handleLogin"
        >
          <svg-icon slot="prefix" icon-class="validCode" class="el-input__icon input-icon" />
        </el-input>
        <div class="login-code">
          <img :src="codeUrl" @click="getCode" class="login-code-img"/>
        </div>
      </el-form-item> -->
      <!-- <el-checkbox v-model="loginForm.rememberMe" style="margin:0px 0px 25px 0px;">记住密码</el-checkbox> -->
      <el-form-item style="width:100%;">
        <el-button
          :loading="registerLoading"
          size="medium"
          type="primary"
          style="width:100%;margin-top: 1rem;"
          @click.native.prevent="handleRegister"
        >
          <span v-if="!registerLoading">注册</span>
          <span v-else>注 册 中...</span>
        </el-button>
        
      </el-form-item>
    </el-form>
    <!--  底部  -->
    <div class="el-login-footer">
      <!-- <div>主办单位：常州市科学技术局</div> -->   
      <div> <a target="_blank" href="https://beian.miit.gov.cn" style="color: rgb(255, 255, 255);text-decoration: none;">
    <span style="color: rgb(255, 255, 255);">苏ICP备19039634号-2</span></a>&nbsp;&nbsp;科技服务热线 0519-88101381</div>
    </div>
  </div>
</template>

<script>
import { Message, MessageBox, Notification, Loading } from 'element-ui'
import Cookies from "js-cookie";
import { encrypt, decrypt } from '@/utils/jsencrypt'
import {setExpiresIn, setToken} from "@/api/auth";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
export default {
  name: "Login",
  components: { Treeselect },
  data() {
    const equalToPassword = (rule, value, callback) => {
      if (this.registerForm.registerPassword !== value) {
        callback(new Error("两次输入的密码不一致"));
      } else {
        callback();
      }
    };
    return {

      subUserType : [
          {id: 100, label: "用户类型",
          children: [
            {label:'仪器管理单位',id:'1'},
            {label:'普通用户',id:'2'}
          ]
        }     
      ],
      selectUserType:true,
      deptOptions: [],
      codeUrl: "",
      cookiePassword: "",
      loginOrRegister:true,
      loginForm: {
        // username: "admin",
        // password: "admin123",
        username: "",
        password: "",
        rememberMe: false,
        code: "",
        uuid: "",
      },
      registerForm: {
        // username: "admin",
        // password: "admin123",
        username:"",
        nickName:"",
        phonenumber:"",
        email:"",
        userType:"1",
        companyName: "",
        unicode: "",
        areaId:"1075",
        registerPassword: "",
        confirmPassword: "",
      },
      loginRules: {
        username: [
          { required: true, trigger: "blur", message: "请输入您的账号" }
        ],
        password: [
          { required: true, trigger: "blur", message: "请输入您的密码" }
        ],
        code: [{ required: true, trigger: "change", message: "请输入验证码" }]
      },
      registerRules:{  

        userType: [
           { required: true, trigger: "change", message: "用户类型不能为空" }
         ],
         
          username: [
            { required: true, trigger: "blur", message: "账户不能为空" }
          ],
          nickName: [
            { required: true, trigger: "blur", message: "所在单位不能为空" },
            { min: 2, max: 100, message: '所在单位长度必须介于 2 和 100 之间', trigger: 'blur' }
          ],
          phonenumber: [
            { required: true, trigger: "blur", message: "手机号不能为空" },
            {
              pattern: /^1[3-9]\d{9}$/,
              message: "手机号格式错误",
              trigger: "blur",
            },
          ],
          email: [
            { required: true, trigger: "blur", message: "邮箱不能为空" },
            {
              pattern: /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/,
              message: "邮箱格式错误",
              trigger: "blur",
            },
          ],
       


        companyName: [
          { required: true, trigger: "blur", message: "企业名称不能为空" },
          { min: 2, max: 100, message: '企业名称长度必须介于 2 和 100 之间', trigger: 'blur' }
        ],
        unicode: [
          { required: true, trigger: "blur", message: "统一社会信用代码不能为空" }
        ],
        areaId: [
          { required: true, trigger: "change", message: "所在区域不能为空" }
        ],

        registerPassword: [
          { required: true, trigger: "blur", message: "请输入您的密码" },
          { min: 5, max: 20, message: '用户密码长度必须介于 5 和 20 之间', trigger: 'blur' }
        ],
        confirmPassword: [
          { required: true, trigger: "blur", message: "请再次输入您的密码" },
          { required: true, validator: equalToPassword, trigger: "blur" }
        ],
      },
      loading: false,
      registerLoading:false,
      // 验证码开关
      captchaOnOff: true,
      // 注册开关
      register: true,
      redirect: undefined
    };
  },
  watch: {
    userType(oldVal,newVal){
      console.log("oldVal",oldVal)
      console.log("newVal",newVal)
        if(newVal==1){
          this.selectUserType=true
        }else{
          this.selectUserType=false
        }
    }
  },
  
  created() {
    this.getCode();
    this.getCookie();
    this.deptTreest();
  },
  methods: {
    change(data, name) {
      if(data==1){
        this.selectUserType=true
        this.restRegister() 
        this.registerForm.userType=1
        this.$refs['registerForm'].clearValidate();
      }
      if(data==2){
        this.selectUserType=false
        this.restRegister() 
        this.registerForm.userType=2
        this.$refs['registerForm'].clearValidate();
      }
      // this.$refs["registerForm"].validateField(name, (valid) => {
      //   console.log(valid);
      // });
      
    },
    getCode() {
      this.$api.login.getCodeImg().then(res => {
        this.captchaOnOff = res.captchaOnOff === undefined ? true : res.captchaOnOff;
        if (this.captchaOnOff) {
          this.codeUrl = "data:image/gif;base64," + res.img;
          this.loginForm.uuid = res.uuid;
        }
      });
    },
    getCookie() {
      const username = Cookies.get("username");
      const password = Cookies.get("password");
      const rememberMe = Cookies.get('rememberMe')
      this.loginForm = {
        username: username === undefined ? this.loginForm.username : username,
        password: password === undefined ? this.loginForm.password : decrypt(password),
        rememberMe: rememberMe === undefined ? false : Boolean(rememberMe)
      };
    },
    deptTreest() {
      // deptList().then((response) => {
      //   this.deptOptions = response.rows;
      // });
      this.deptOptions = [
          {id: 100, label: "常州大科技",
          children: [
            {label:'市属',id:'1075'},
            {label:'武高新',id:'204'},
            {label:'科教城',id:'205'},
            {label:'西太湖',id:'206'},
            {label:'武进其他',id:'108'},
            {label:'天宁区',id:'102'},
            {label:'新北区',id:'103'},
            {label:'金坛区',id:'104'},
            {label:'溧阳市',id:'105'},
            {label:'钟楼区',id:'106'},
            {label:'经开区',id:'107'}
          ]
        }     
      ];
    },
    normalizer() {},
    handleLogin() {
      this.$refs.loginForm.validate(valid => {
        if (valid) {
          this.loading = true;
          if (this.loginForm.rememberMe) {
            Cookies.set("username", this.loginForm.username.trim(), { expires: 30 });
            Cookies.set("password", encrypt(this.loginForm.password), { expires: 30 });
            Cookies.set('rememberMe', this.loginForm.rememberMe, { expires: 30 });
          } else {
            Cookies.remove("username");
            Cookies.remove("password");
            Cookies.remove('rememberMe');
          }
          this.loginForm.username=this.loginForm.username.trim();
          this.$api.login.login(this.loginForm).then((res) => {
            if(res){
              if(res.code==500){            
                // this.$notifyError(res.msg)
                // Notification.info(res.msg)
                Message.error(res.msg)
                this.loading = false;
                this.getCode();
              }else{
                setToken(res.data.access_token)
                setExpiresIn(res.data.expires_in)
                localStorage.setItem('userType',res.data.user_type)
                this.$router.push({ path: this.redirect || "/" }).catch(()=>{});
              }
            }          
          }).catch(() => {
            this.loading = false;
            if (this.captchaOnOff) {
              this.getCode();
            }
          });
        }
      });
    },
    handleRegister() {
      console.log("handleRegister");
      this.$refs.registerForm.validate(valid => {
        if (valid) {      
          console.log("registerForm"); 
          this.$api.login.register(this.registerForm).then(res => {
            if(res){
              if(res.code==500){  
                Message.error(res.msg)
              }else{
                console.log("this.registerForm.unicode",this.registerForm.unicode)
                console.log("this.registerForm.username",this.registerForm.username)
                const username = (this.registerForm.unicode!=null?this.registerForm.unicode:this.registerForm.username);
                this.$alert("恭喜你，您的账号 " + username + " 注册成功", '系统提示', {        
              }).then(() => {
                this.restRegister()             
                this.loginOrRegister=true;
                this.registerLoading = true;
              }).catch(() => {});
              }
            }           
          });
        }
      });
    },
    restRegister(){
      this.registerForm = {
        companyName: null,
        unicode: null,
        areaId:"1075",
        registerPassword: null,
        confirmPassword: null,
        username:null,
        nickName:null,
        phonenumber:null,
        email:null,
      }
    },
    toRegister(){
        this.loginOrRegister=false;
        this.registerLoading=false;
        this.$refs['loginForm'].clearValidate();
    },
    toLogin(){
      this.loginOrRegister=true;
      this.$refs['registerForm'].clearValidate();
    }
  }
};
</script>

<style rel="stylesheet/scss" lang="scss">
.login {
  // display: flex;
  // justify-content: center;
  padding-left: 28%;
  padding-right: 28%;
  align-items: center;
  height: 100%;
  background-image: url("../assets/images/login-background.jpg");
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly
}
.pagelogo{
  // text-align: center;
  position: fixed;
  top: 10%;
}
.title {
  //margin: 0px auto 30px auto;
  text-align: center;
  //color: #707070;
  margin-left: -25px;
  // background: #4986d7;
  margin-right: -25px;
  height: 50px;
  margin-top: -25px;
  line-height: 50px;
  border-radius: 6px 6px 0 0;
  color: white;
  letter-spacing: 10px;
  font-weight: 600;
  font-size: 24px;
}

.login-form {
  margin-left: 25%;
  margin-right: 25%;
  margin-top: 2%;
  border-radius: 6px;
  background: #ffffff;
  width: 50%;
  min-width: 400px;
  height: 380px;
  padding: 25px 25px 5px 25px;
  .el-input {
    height: 38px;
    input {
      height: 38px;
    }
  }
  .input-icon {
    height: 39px;
    width: 14px;
    margin-left: 2px;
  }
}
.register-form {
  margin-left: 25%;
  margin-right: 25%;
  margin-top: 2%;
  border-radius: 6px;
  background: #ffffff;
  width: 50%;
  min-width: 400px;
  height: 570px;
  padding: 25px 25px 5px 25px;
  .el-input {
    height: 38px;
    input {
      height: 38px;
    }
  }
  .input-icon {
    height: 39px;
    width: 14px;
    margin-left: 2px;
  }
}
.login-tip {
  font-size: 13px;
  text-align: center;
  color: #bfbfbf;
}
.login-code {
  width: 33%;
  height: 38px;
  float: right;
  img {
    cursor: pointer;
    vertical-align: middle;
  }
}
.el-login-footer {
  height: 80px;
  line-height: 80px;
  position: fixed;
  left: 0%;
  bottom: 0;
  width: 100%;
  text-align: center;
  color: #fff;
  font-family: Arial;
  font-size: 12px;
  letter-spacing: 1px;
}
.el-login-footer div{
  height: 20px;
}
.login-code-img {
  height: 38px;
}
.link-type, .link-type:focus {
    color: #337ab7;
    cursor: pointer;
}
</style>
