<template>
    <div>
        <div ref="projectMap" class="echart"></div>
    </div>
</template>
<script>
import changzhou from "@/assets/json/320400.json";
export default {
    props:{
        mapData:Object,
        clientWidth:Number
    },
    data(){
        return{
            barClickData:{},
        }
    },
    watch:{
        'mapData':{
            deep:true,
            immediate:true,
            handler(val){
                console.log('mapDatamapDatamapDatamapData',val);
                setTimeout(()=>{
                    this.echartsInit();
                },800)
            }
        }
    },
    // mounted(){
    //     setTimeout(()=>{
    //         this.echartsInit();
    //     },500)
    // },
    methods:{
        echartsInit(){
            this.$echarts.registerMap("常州", changzhou);
            let Echarts = this.$echarts.init(this.$refs.projectMap);
            Echarts.clear();
            // var current = '';
            const option = {
                geo3D: {
                    map: '常州',
                    // show: false,
                    itemStyle: {
                        color: "rgba(1, 16, 31, 0)", //将geo3d数据设置为透明
                        opacity: 0, //透明
                        borderColor: "rgba(1, 16, 31, 0)",
                        borderWidth: 0
                    },
                    viewControl: {
                        zoomSensitivity: 0, // 缩放操作的灵敏度
                        rotateSensitivity: 1, // 旋转操作的灵敏度
                        alpha:40,
                        beta:150,
                    },
                    // width:'90%',
                    // height:'90%'
                    top:0
                },
                series: [
                    // bar
                    {
                        type: 'bar3D',
                        coordinateSystem: 'geo3D',
                        zlevel: 3,
                        barSize: 2, // 柱子粗细
                        minHeight:1,
                        shading: 'lambert',
                        bevelSize:0.7,
                        bevelSmoothness:5,
                        itemStyle: {
                            color:"#4DF7AF"
                        },
                        label: {
                            show: true,
                            position: 'top',
                            textStyle: {
                                color:'#fff',
                                backgroundColor: 'transparent'
                            },
                            formatter(params) {
                                return params.name + ' ' + params.value[2]
                            }
                        },
                        data:[
                            {name:'天宁区',value:[120.060044,31.801986]},
                            {name:'钟楼区',value:[119.858011,31.787817]},
                            {name:'新北区',value:[119.886314,31.924334]},
                            {name:'金坛区',value:[119.509004,31.717248]},
                            {name:'溧阳市',value:[119.376273,31.419784]},
                            {name:'经开区',value:[120.065789,31.668167]},
                            {name:'武进其他',value:[119.770922,31.598712]},
                            {name:'科教城',value:[119.960255,31.682745]},
                            {name:'武高新',value:[119.972681,31.607952]},
                            {name:'西太湖',value:[119.856535,31.706096]},
                            {name:'市属',value:[119.944092,31.851313]}
                        ]
                    },
                    {
                        type: 'map3D',
                        map: '常州',
                        regionHeight:3,
                        zlevel: 0,
                        boxWidth: 120,
                        boxHeight: 30, // 4:没有bar. 30:有bar,bar最高度30，按比例分配高度
                        left:0,
                        // postEffect:{
                        //     enable:true,
                        //     bloom:{
                        //         enable:true
                        //     }
                        // },
                        itemStyle: {
                            borderWidth: 1,
                            borderColor: "#2d90f2",
                            color: "#02418d",
                            opacity: 0.5,
                        },
                        label:{
                            show:false
                        },
                        emphasis: {
                            label: {
                                show: false,
                            },
                            borderWidth: 2,
                            borderColor: "#59e3ff",
                            itemStyle: {
                                color: '#0761bb',
                                // opacity: 0.8
                            }
                        },
                        light:{
                            main:{
                                shadow: false,
                                // shadowQuality: 'ultra',
                                intensity: 1,
                                // alpha: 40,
                                // beta: 300
                            },
                            ambient:{
                                color:'#fff',
                                intensity:0.2
                            }
                        },
                        viewControl: {
                            projection: 'perspective',
                            // damping: 0,
                            rotateSensitivity: 1, // 旋转操作的灵敏度
                            rotateMouseButton: 'left', // 旋转操作使用的鼠标按键
                            zoomSensitivity: 0, // 缩放操作的灵敏度
                            panSensitivity: 1, // 平移操作的灵敏度
                            distance: this.clientWidth > 2000 ? 135 : 125, // 默认视角距离主体的距离
                            center: [0, 0, 0],
                            autoRotate:false,
                            alpha:40,
                            beta:150,
                        },
                        // width:'90%',
                        // height:'90%',
                        top:0,
                        data:[
                            // {name:'天宁区',value:0,itemStyle:{color:'#497568'}},
                            // {name:'新北区',value:0,itemStyle:{color:'#666699'}},
                            // {name:'钟楼区',value:0,itemStyle:{color:'#617172'}},
                            // {name:'金坛区',value:0,itemStyle:{color:'#1781b5'}},
                            // {name:'溧阳市',value:0,itemStyle:{color:'#99CCCC'}},
                            // {name:'武进区',value:0,itemStyle:{color:'#6699CC'}},
                            {name:'天宁区',value:0},
                            {name:'新北区',value:0},
                            {name:'钟楼区',value:0},
                            {name:'金坛区',value:0},
                            {name:'溧阳市',value:0},
                            {name:'武进区',value:0},
                        ]
                    }
                ]
            }
            //给data赋值
            option.series[0].data.forEach(item => {
                if(this.mapData[item.name]){
                    item.value.push(this.mapData[item.name])
                }else{
                    item.value.push(0)
                }
            });
            option.series[1].data.forEach(item => {
                if(this.mapData[item.name]){
                    item.value = this.mapData[item.name]
                }else{
                    item.value = 0
                }
            });
            // 给数据最大的改变颜色
            let indexArr = [];
            let arrs = option.series[0].data.map((value, index, array) => {
                return value.value[2]
            })
            let max = Math.max(...arrs);
            option.series[0].data.forEach((item,i) => {
                if(item.value[2]  ==  max){
                    indexArr.push(i)
                }
            })
            indexArr.forEach(item => {
                option.series[0].data[item].itemStyle = {color:'#F9F95A'}
            })

            Echarts.setOption(option);
            window.onresize = Echarts.resize();
            Echarts.on('click','series.bar3D', params => {
                console.log(params,'params------');
                this.barClickData = params;
                this.$emit('change',params.name);
            });
            Echarts.on('click','series.map3D', params => {
                console.log('地图点击事件：',params);
                let mapArea = {
                    '武进区': ['武进其他','武高新','西太湖','科教城','经开区'],
                    '新北区': ['新北区','市属'],
                    '天宁区': ['天宁区'],
                    '钟楼区': ['钟楼区'],
                    '金坛区': ['金坛区'],
                    '溧阳市': ['溧阳市'],
                }
                if(mapArea[params.name].indexOf(this.barClickData.name) == -1){
                    //地图点击事件的name与bar点击事件的name不存在关联性，表示点击的是地图，emit对应数据；
                    //若存在关联性，表示是bar点击事件出发了地图点击事件，地图不用emit对应数据
                    this.$emit('change',params.name);
                }
                for(let i=0;i<option.series[1].data.length;i++){
                    if(params.name == option.series[1].data[i].name){
                        option.series[1].data[i]['itemStyle'] = {color:'#0761bb'}
                    }else{
                        delete option.series[1].data[i]['itemStyle']
                    }
                }
                Echarts.setOption(option);
            });
        },
    }
}
</script>
<style lang="scss" scoped>
    .echart{
        height: 50vh;
        width: 45vw;
    }
</style>