<template>
    <div>
        <header-logo></header-logo>
        <div class="banner-sub">
            <div id="animateBox" class="box animated bounceInLeft">
                {{title}}
            </div>
        </div>
        <div class="section-sub">
            <div class="container clearfix"> 
                <!--侧边栏-->
                <ul class="v-lanmu">
                    <li v-for="(item,index) in rightNewsList" :key="index">
                        <a @click="goToDetail(item.id)">
                            <strong>{{item.name && item.name.length > 17 ? item.name.slice(0,16)+'...' : item.name}}</strong>{{item.dict_label}}
                        </a>
                    </li>
                </ul>
                <div class="v-main" v-loading="loading"> 
                    <!--子栏目导航-->
                    <ul class="h-lanmu">
                        <li v-for="(item,index) in types" :key="index" :class="{'active':Object.keys(checkType).length && checkType.dict_code == item.dict_code}">
                            <a @click="selectTypes(item)">{{item.dict_label}}</a>
                        </li>
                    </ul>
                    <ul class="list-3" v-if="newsList.length">
                        <li v-for="(item,index) in newsList" :key="index">
                            <a @click="goToDetail(item.id)">
                                <!-- <div class="img-center"><img :src="item.image" alt=""></div> -->
                                <div class="text">
                                    <h4>{{item.name}}</h4>
                                    <p>{{item.outline && item.outline.length > 77 ? item.outline.slice(0,77)+'...' : item.outline}}</p>
                                    <span>日期：{{item.orgcreatetime ? item.orgcreatetime.slice(0,10) : ''}}</span>
                                </div>
                            </a> 
                        </li>
                    </ul>
                    <div v-else style="text-align:center;display:none;margin-bottom:250px" id="empty">
                        <img src="../assets/img/empty.png">
                        <div style="font-size:22px;color:#868686">暂无数据</div>
                    </div>
                    <!--分页导航-->
                    <div class="block">
                        <el-pagination
                            v-if="newsList.length"
                            @size-change="handleSizeChange($event,true)"
                            @current-change="handleCurrentChange($event,true)"
                            :current-page="queryParams.pageNum"
                            :page-sizes="[10, 20, 30, 40]"
                            :page-size="queryParams.pageSize"
                            :total="total"
                            layout="total, sizes, prev, pager, next, jumper"
                        >
                        </el-pagination>
                    </div>
                </div>
            </div>
        </div>
        <footer-index></footer-index>
    </div>
</template>
<script>
import headerLogo from './components/header.vue';
import footerIndex from './components/footer.vue';
import DevicePixelRatio from '../devicePixelRatio';
export default {
    components:{headerLogo,footerIndex},
    data(){
        return{
            queryParams:{
                pageNum:1,
                pageSize:10
            },
            total:2,
            types:[],
            checkType:{},//选中type
            newsList:[],
            rightNewsList:[],
            title:'政策法规',
            loading:true,
        }
    },
    beforeCreate() {
        new DevicePixelRatio().init();
	},
    created(){
        this.getTypes();
        this.getRightList();
        if(this.$route.query.type){
            this.checkType = this.$route.query.type;
            this.title = ''
        }
    },
    watch: {
	    // 监听value发生变化，动效生效
        title (newVal, oldVal) {
            if(newVal == ''){
                const ele = document.getElementById('animateBox') // 动效所在元素的DOM
                ele.className = ele.className.replace('bounceInLeft', '') // 先删除动效类名
                setTimeout(() => {
                    this.title = this.checkType.dict_label;
                    ele.className += 'bounceInLeft' // 然后再设置延迟增加动效类名
                }, 50)
            }        
        },
	},
    methods:{
        getTypes(){
            this.$api.news.newsType().then(res=>{
                if(res.rows){
                    this.types = res.rows;
                    // this.checkType = this.types[0];
                    this.getList()
                }
            })
        },
        selectTypes(data){
            if(!data)return;
            this.checkType = data;
            this.title = '';
            this.queryParams.pageNum = 1;
            this.getList();
            // localStorage.setItem('checkType',JSON.stringify(this.checkType))
        },
        getList(){
            if(Object.keys(this.checkType).length && this.checkType.dict_code){
                this.queryParams['type'] = this.checkType.dict_code;
            }
            this.$api.news.getList(this.queryParams).then(res => {
                this.total = res.total;
                this.newsList = res.rows;
                this.loading = false;
                if(!this.newsList.length){
                    document.getElementById('empty').style.display = 'block'
                }
            })
        },
        getRightList(){
            this.$api.news.getRightList().then(res=>{
                if(res.rows){
                    this.rightNewsList = res.rows
                }
            })
        },
        handleSizeChange(newSize) {
            this.queryParams.pageSize = newSize;
            this.getList()
        },
        handleCurrentChange(newPage) {
            this.queryParams.pageNum = newPage;
            this.getList()
        },
        goToDetail(id){
            this.$router.push({
                path:'/newsDetail',
                query:{
                    id:id,
                    type:this.checkType
                }
            })
        },
        back(){
            this.$router.push('/index')
        },
    }
}
</script>
<style lang="scss" scoped>
* {
    margin: 0;
    padding: 0;
}

p{
    text-indent:0
}
.back-home{
    position: fixed;
    bottom: 20px;
    right: 30px;

    width: 60px;
    height: 60px;
    background-color: #2c2c2c66;
    border-radius: 30px;
    img{
        position: relative;
        top: 0px;
        transform: translateY(45%);
    }
    text-align: center;
}
    a {
        color: #333;
        text-decoration: none;
    }
    .banner-sub{
        height: 410px;
        line-height: 410px;
        background: no-repeat center / cover;
        color: #fff;
        text-align: center;
        font-size: 44px;
        text-shadow: 0 2px 2px #333333;
        background-image: url('../assets/img/banner.jpg');
    }

    .section-sub{
        background-color: #f8f8f8;
        padding: 40px 0;

        .container{
            padding: 0 15px;
            width: 1200px;
            margin: 0 auto;
        }
    }

    ul {
        margin: 0;
        list-style: none;
        padding: 0;
    }

    .v-lanmu {
        float: right;
        width: 160px;

        li {
            margin-bottom: 10px;
            font-size: 14px;

            a {
                display: block;
                width: 90px;
                padding: 20px;
                border: 1px solid #ddd;
                background-color: #fff;
                transition: all 0.5s;
            }

            a:after {
                display: block;
                content: '';
                width: 20px;
                height: 2px;
                background-color: #68a3d8;
                margin-top: 10px;
                transition: all 0.5s;
            }

            a:hover {
                background-color: #68a3d8;
                color: #fff;
                border-color: #68a3d8;
                width: 118px;
                cursor: pointer;
            }

            a:hover:after {
                background-color: #fff;
                cursor: pointer;
            }

            strong {
                display: block;
                line-height: 1.1;
                margin-bottom: 5px;
            }
        }
    }

    .v-main {
        margin-right: 200px;
        min-height: 600px;

        .h-lanmu {
            margin-bottom: 20px;

            .active a {
                background-color: #68a3d8;
                color: #fff;
            }

            li {
                display: inline-block;
                vertical-align: middle;
                margin: 0 10px 10px 0;

                a {
                    display: block;
                    padding: 0 30px;
                    line-height: 50px;
                    background-color: #fff;
                }

                a:hover {
                    background-color: #68a3d8;
                    color: #fff;
                    cursor: pointer;
                }
            }
        }

        .list-3{
            li {
                margin-bottom: 20px;

                a {
                    display: block;
                    overflow: hidden;
                    background-color: #fff;
                    padding: 20px;
                }

                a:hover {
                    box-shadow: 0 0 20px #eeeeee;
                    color: #68a3d8;
                    cursor: pointer;
                }

                .img-center {
                    float: left;
                    width: 204px;
                    text-align: center;
                    font-size: 0;

                    img {
                        display: inline-block;
                        width: auto;
                        height: auto;
                        max-width: 100%;
                        max-height: 100%;
                        vertical-align: middle;
                        transition: all 0.5s;
                    }
                }

                .text {
                    margin-left: 0px;

                    h4 {
                        margin: 0;
                        font-size: 22px;
                        font-weight: normal;
                    }

                    p {
                        color: #999;
                        margin: 15px 0 0;
                        line-height: 1.7;
                    }

                    span {
                        display: block;
                        margin-top: 15px;
                        font-size: 12px;
                        color: #999;
                    }
                }
            }
        }

        .block {
            text-align: center;
            margin: 0 auto;
            margin-bottom: 30px;
        }
    }
</style>