<template>
    <div class="header">
        <el-row>
            <el-col :span="10">
                <div class="title" @click="back()" style="cursor:pointer">
                    <img src="../../assets/img/logo_title1.png">
                </div>
            </el-col>
            <el-col :span="14">
                <div class="navbar">
                    <ul>
                        <li>
                            <router-link :to="{ path: '/index' }">
                                首页
                            </router-link>
                        </li>
                        <li>
                            <span>|</span>
                        </li>
                        <li>
                            <router-link :to="{ path: '/screen' }">
                                云地图
                            </router-link>
                        </li>
                        <li>
                            <span>|</span>
                        </li>
                        <li>
                            <router-link :to="{ path: '/home' }">
                                仪器共享
                            </router-link>
                        </li>
                        <li>
                            <span>|</span>
                        </li>
                        <li>
                            <router-link :to="{ path: '/newsList' }">
                                政策法规
                            </router-link>
                        </li>
                        <li v-if="headFlag!=2">
                            <span>|</span>
                        </li>
                        <li v-if="headFlag!=2">
                            <a href="https://www.czkjgx.com/ent/index" target="_blank">
                                企业平台
                            </a>
                        </li>
                      <li>
                        <span>|</span>
                      </li>
                      <li>
                        <span style="color: rgb(53, 152, 219); cursor: pointer;" @click="logout" v-if="hasToken">
                          退出登录
                        </span>
                      </li>
                    </ul>
                </div>
                <div class="tel">
                    <img style="height: 25px;vertical-align:middle" src="../../assets/img/phone.png" />
                    <div style="display:inline-block;line-height:55px;font-size:20px;margin-left:8px">
                        <span>咨询电话：</span>
                        <span style="color:#3598DB">0519-88101381</span>
                    </div>
                </div>
            </el-col>
        </el-row>
    </div>
</template>
<script>
import {getToken, removeToken} from "@/api/auth";

export default {
    props:{
        backToIndex:{
            type:Boolean,
            default:true
        }
    },
  computed:{
    hasToken: ()=>{
      return !!getToken()
    }
  },
    data(){
        return{
            clientWidth:document.body.clientWidth,
            headFlag:localStorage.getItem('userType')
        }
    },
    methods:{
        back(){
            if(this.backToIndex){
                this.$router.push('/index')
            }
        },
      logout() {
          this.$api.login.logout().then(res => {
            removeToken();
            this.$router.push('/login')
          });
      }
    }
}
</script>
<style lang="scss" scoped>
.header {
    width: 100%;
    position: relative;
    height: 90px;
    text-align: center;
    .title{
        width: 100%;
        text-align: center;
        display: inline-block;
        position: relative;
        top: 0px;
        transform: translateY(20%);

        img{
            // width: 100%;
            height: 65px;
        }
    }
    .navbar{
        // width: 60%;
        text-align: left;
        display: inline-block;
        line-height: 90px;
        height: 90px;
        // width: 100%;
        ul{
            list-style: none;
            margin: 0;
            padding: 0;

            li{
                display: inline-block;
                margin-left: 8px;
                font-size:18px;

                span{
                    color: #C5C5C5;
                }

                a{
                    text-decoration: none;
                    color: #000;
                }

                a:hover{
                    color: #3598db;
                }
            }
        }
    }
    .tel{
        // width: 100%;
        // text-align: right;
        display: inline-block;
        width: 40%;
        // position: relative;
        // top: 0px;
        // transform: translateY(30%);
        // padding-right: 10%;
    }
}
</style>
