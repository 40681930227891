<template>
    <div class="foot" style="width: 100vw">
        <el-row :gutter="10" style="width: 1190px; margin: 0 auto">
            <el-col :span="6">
                <div class="foot-c3-1 foot-cbg">
                    关于平台<br />
                    &nbsp;&nbsp; <br />
                    <a target='_blank' href="https://www.czkjgx.com/ent/index">
                        管理登陆
                    </a>
                </div>
            </el-col>
            <el-col :span="6">
                <div class="foot-c3-1 foot-cbg">
                    科技服务热线<br />
                    &nbsp;&nbsp; <br />
                    0519-88101381
                </div>
            </el-col>
            <el-col :span="6">
                <div class="foot-c3-1 foot-cbg">
                    常州市大型科研仪器设备云平台<br />
                    &nbsp;&nbsp; <br />
                    <a href="https://beian.miit.gov.cn" target="_blank">苏ICP备19039634号-2</a>
                </div>
            </el-col>
            <el-col :span="6">
                <div class="foot-c3-1 foot-cbg">
                    技术支持<br />
                    &nbsp;&nbsp; <br />
                    <a href="http://www.rextec.com.cn/" target="_blank">
                        常州瑞克斯信息科技有限公司
                    </a>
                </div>
            </el-col>
        </el-row>
    </div>
</template>
<script>
export default {
    data(){
        return{}
    }
}
</script>
<style lang="scss" scoped>
* {
    margin: 0;
    padding: 0;
}
.foot {
    background: #4477b9;
    height: 120px;
    color: #fff;
    text-align: center;
    font-size: 16px;
    width: 100% !important;
}
.foot-cbg {
    background: #5784c0;
    border-radius: 3px;
    margin-top: 10px;
}
.foot-c3-1 {
    padding: 15px 0 0;
    margin-bottom: 10px;
    height: 85px;
}
.foot a {
    color: #fff;
    cursor: pointer;
    text-decoration: none;
}

.foot a:hover {
    text-decoration: underline;
    cursor: pointer;
}
</style>
