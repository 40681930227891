<template>
  <div class="home">
    <div class="head jz">
      <header-logo></header-logo>
    </div>
    <div class="backGround">
      <h3>常州市大型科学仪器设备信息查询</h3>
    </div>
    <div class="yqss-nav">
      <ul class="jz">
        <li class="yqss-nav-h">
          <a href="#">科学仪器设备</a>
        </li>
      </ul>
    </div>
    <div style="width:1190px;margin:0 auto">
      <div class="yqss-sx">
        <div class="yqss-sx-jg">
          <div class="ssjg-l">
            <span class="thingt">已选条件:</span>
            <el-tag
                style="margin-right:10px"
                closable
                v-if="show['name'] && selectName"
                @close="handleClose('name')"
                >仪器关键词：{{ selectName }}</el-tag
              >
              <el-tag
                style="margin-right:10px"
                closable
                v-if="show['deptName'] && selectCompanyName"
                @close="handleClose('deptName')"
                >企业关键词：{{ selectCompanyName }}</el-tag
              >
              <el-tag
                style="margin-right:10px"
                closable
                v-if="show['areaName'] && searchParam['areaName']"
                @close="handleClose('areaName')"
                >所在地区：{{ searchParam["areaName"] }}</el-tag
              >
              <el-tag
                style="margin-right:10px"
                closable
                v-if="show['cifyName'] && searchParam['cifyName']"
                @close="handleClose('cifyName')"
                >仪器分类编码：{{ searchParam["cifyName"] }}</el-tag
              >
          </div>

          <a class="ssjg-r" @click="clearair">清空筛选条件</a>
        </div>
        <div class="yqss-sx-jg">
          <div class="ssjg-l">
            <span class="thingt">仪器名称:</span>
            <el-input
              v-model="searchParam['name']"
              placeholder="仪器名称"
              prefix-icon="el-icon-search"
              class="input1"
              size="small"
            >
            </el-input>
            <div class="eyeserch">
              <el-checkbox v-model="checked" class="duoxuan"
                >精准匹配</el-checkbox
              >
              <el-button class="but" @click="seacher">搜索</el-button>
            </div>
          </div>
        </div>
        <div class="yqss-sx-jg">
          <div class="ssjg-l">
            <span class="thingt">企业名称:</span>
            <el-input
              v-model="searchParam['deptName']"
              placeholder="企业名称"
              prefix-icon="el-icon-search"
              class="input1"
              size="small"
            >
            </el-input>
          </div>
        </div>
        <div class="yqss-sx-jg">
          <div class="ssjg-l">
            <span class="thingt">所在地区:</span>
            <template v-for="(area, index) in selectArea">
              <el-button
                type="text"
                class="buttext"
                v-if="area.deptName"
                :key="index"
                @click="seacherRegion(area.deptName, area.dept_id)"
                >{{ area.deptName }}
                <span>{{ area.sumCount }}</span>
              </el-button>
            </template>
          </div>
        </div>
        <div class="yqss-sx-jg" style="border-bottom:1px solid #dedede">
          <div class="ssjg-l">
            <span class="thingt">仪器分类编码:</span>
            <el-select
              v-model="searchParam.cify"
              placeholder="请选择"
              size="small"
              @change="selectCode(searchParam.cify)"
            >
              <el-option
                v-for="(item,index) in Encodingdata"
                :key="index"
                :label="item.dict_name"
                :value="item.dict_id"
              >
              </el-option>
            </el-select>
            <el-select
              v-model="searchParam.secondCify"
              placeholder="请选择"
              size="small"
              @change="selectCode2(searchParam.secondCify)"
            >
              <el-option
                v-for="(item,index) in EncodingdataSec"
                :key="index"
                :label="item.dict_label"
                :value="item.dict_code"
              >
              </el-option>
            </el-select>
            <el-select
              v-model="searchParam.thirdCify"
              placeholder="请选择"
              size="small"
              @change="selectCode3(searchParam.thirdCify)"
            >
              <el-option
                v-for="item in Encodingdatathree"
                :key="item.index"
                :label="item.dict_label"
                :value="item.dict_code"
              >
              </el-option>
            </el-select>
          </div>
        </div>
      </div>
      <div class="yqss-tab" id="3F">
        <div class="ordination">
          <el-button type="text" class="buttext2" @click="nameData()" :style="{color: searchParam['order'] == '' ? '#ff6e47' : 'grey'}"
            >默认排序</el-button
          >
          <el-button type="text" class="buttext2" @click="nameData('name')" :style="{color: searchParam['order'] == 'name' ? '#ff6e47' : 'grey'}"
            >按仪器名称</el-button
          >
          <el-button type="text" class="buttext2" @click="nameData('deptName')" :style="{color: searchParam['order'] == 'deptName' ? '#ff6e47' : 'grey'}"
            >按企业名称</el-button
          >
          <el-button type="text" class="buttext2" @click="nameData('areaName')" :style="{color: searchParam['order'] == 'areaName' ? '#ff6e47' : 'grey'}"
            >按所在地区</el-button
          >
          <el-button type="text" class="buttext2" @click="nameData('cify')" :style="{color: searchParam['order'] == 'cify' ? '#ff6e47' : 'grey'}"
            >按分类编码</el-button
          >
        </div>
        <div class="lenth">
          <p style="text-indent:0">
            找到<span>{{ total}}条 关键字</span>相关结果
          </p>
        </div>
        <dl class="yqss-tab2">
          <dd class="yqss-tab2-hover" id="tab1"></dd>
          <dt id="tab2"></dt>
        </dl>
      </div>
      <div class="yqss-nr">
        <div v-for="strumInfo in filterData" :key="strumInfo.areaId">
          <ul class="yqss-c2" id="instr2">
            <li>
              <router-link target='_blank' :to="{ name: 'detail', query: { id: strumInfo.id } }"
                ><h3>
                  <a>{{ strumInfo.name }}</a>
                </h3></router-link
              >
              <b>{{ strumInfo.deptName }}</b>
              <div>
                <p style="text-indent:0"><span>型号：</span>{{ strumInfo.type }}</p>
                <p style="text-indent:0"><span>安置地：</span>{{ strumInfo.areaName }}</p>
              </div>
            </li>
          </ul>
        </div>
        <div class="prompt-msg" v-if="total === 0">
          当前无满足搜索条件的数据
        </div>
        <div style="display: none">
          <ul class="yqss-c1 clearfix" id="instr"></ul>
          <div class="ac_page"><div class="ym" id="ym"></div></div>
        </div>
        <div class="block1" v-if="filterData.length > 0">
    
          <el-pagination
            @size-change="handleSizeChange($event,true)"
            @current-change="handleCurrentChange($event,true)"
            :current-page="queryParams.pageNum"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="queryParams.pageSize"
            :total="total"
            layout="total, sizes, prev, pager, next, jumper"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <footer-index></footer-index>
  </div>
</template>

<script>
import headerLogo from './components/header.vue';
import footerIndex from './components/footer.vue';
import DevicePixelRatio from '../devicePixelRatio';
export default {
  components:{headerLogo,footerIndex},
  data() {
    return {
      tlnstrumInfodata: [],//列表
      filterData: [],
      instrumenInfodata: [],
      Encodingdata: [],
      Encodingdatathree: [],
      devicedata: [],
      //搜索条件
      searchParam: {
        name: "", //仪器名称
        deptName: "", //企业名称
        cifyName: "", //三级分类编码名称
        cify: "", //一级分类编码
        secondCify: "", //二级分类编码
        thirdCify: "", //三级分类编码
        areaName: "", //地区
        areaId:"",//地区id
        order:'',//排序
      },
      queryParams:{
        pageNum: 1,
        pageSize: 10,
      },
      show:{
        name:false,
        deptName:false,
        areaName:false,
        cifyName:false
      }, //是否显示搜索条件
      checked: false,
      EncodingdataSec:[],//级联二级数据
      total:0,
      selectArea:[],//已选地区选择后的数组
      selectName:'',//删选条件显示
      selectCompanyName:'',//删选条件显示
    };
  },
  beforeCreate() {
    new DevicePixelRatio().init();
	},
  created() {
    this.getData();
    // this.getArea();
    this.getNum();
  },
  methods: {
    back(){
        this.$router.push('/index')
    },
    //获取列表
    getData(searchArea = true) {
      if(this.checked){
        this.searchParam.match = '1'
      }else{
        delete this.searchParam.match
      }
      let obj = {};
      Object.assign(obj,this.searchParam);
      if(!obj['thirdCify'] || !obj['secondCify']){
        if(obj['secondCify']) delete obj['secondCify']
        if(obj['cify']) delete obj['cify']
      }
      this.$api.home.getList({...obj,...this.queryParams}).then((res) => {
        this.tlnstrumInfodata = res.rows;
        this.filterData = res.rows;
        this.total = res.total;
        if(searchArea)this.getArea()
      });
    },
    // 获取地区
    getArea() {
      this.$api.home.getRegion(this.searchParam).then((res) => {
        this.devicedata = res.rows;
        if(this.searchParam.areaId){
          this.selectArea = this.devicedata.filter(x => x.dept_id == this.searchParam.areaId);
          this.show.areaName = true;
        }else{
          this.selectArea = this.devicedata;
        }
      });
    },
    // 获取一级仪器编码
    getNum() {
      this.$api.home.getEncoding(this.searchParam).then((res) => {
        this.Encodingdata = res.rows;
      });
    },
    //选择一级数据，赋值二级数据
    selectCode(Num1) {
      console.log(Num1);
      this.EncodingdataSec = this.Encodingdata.filter(x=>x.dict_id == Num1)[0].sub;
      if(this.searchParam['secondCify']) this.searchParam['secondCify'] = "";
      if(this.searchParam['thirdCify'])this.searchParam['thirdCify'] = "";
    },
    //
    selectCode2(Num2){
      //获取三级数据
      this.$api.home.getEncodingthree({ dict_code: Num2 }).then((res) => {
        this.$nextTick(()=>{
          this.Encodingdatathree = res.rows;
        })
      });
      if(this.searchParam['thirdCify'])this.searchParam['thirdCify'] = "";
    },
    selectCode3(Num3){
      this.searchParam['cifyName'] =  this.Encodingdatathree.filter(x=>x.dict_code == Num3)[0].dict_label
      this.show.cifyName = true;
      this.handleCurrentChange(1)
      this.getData();
    },

    //地区筛选
    seacherRegion(name, id) {
      this.searchParam.areaName = name;
      this.searchParam.areaId = id;
      this.show.areaName = true;
      this.selectArea = this.devicedata.filter(x => x.dept_id == id);
      this.handleCurrentChange(1)
      this.getData(false)
    },
    //清除筛选条件
    handleClose(name) {
      if(name == 'areaName'){
        this.searchParam['areaName'] = '';
        this.searchParam['areaId'] = '';
        this.selectArea = this.devicedata;
      }else if(name == 'cifyName'){
        this.searchParam['cifyName'] = '';
        this.searchParam['cify'] = '';
        this.searchParam['secondCify'] = '';
        this.searchParam['thirdCify'] = '';
      }else{
        this.searchParam[name] = '';
      }
      this.show[name] = false;
      this.handleCurrentChange(1)
      this.getData();
    },
    //按仪器名称排序
    nameData(type) {
      if (type) {
        this.searchParam.order = type;
        this.handleCurrentChange(1)
        this.$api.home.getList({ ...this.queryParams,...this.searchParam}).then((res) => {
          this.filterData = res.rows;
        });
      } else {
        this.searchParam.order = '';
        this.handleCurrentChange(1)
        this.getData();
      }
    },
    // 按企业名称搜索  仪器名称搜索
    seacher() {
      if(this.searchParam['name']){
        this.show.name = true;
        this.selectName = this.searchParam['name']
      }else{
        this.show.name = false;
        this.selectName = ''
      }
      if(this.searchParam['deptName']){
        this.show.deptName = true;
        this.selectCompanyName = this.searchParam['deptName']
      }else{
        this.show.deptName = false;
        this.selectCompanyName = ''
      }
      this.handleCurrentChange(1)
      if(this.searchParam['name'] || this.searchParam['deptName']) this.getData();
    },
    //清空已选条件
    clearair() {
      this.isShowSearchParam = false;
      for(let key in this.searchParam){
        this.searchParam[key] = '';
      }
      this.handleCurrentChange(1)
      this.getData();
    },

    handleSizeChange(newSize,isSearch=false) {
      this.queryParams.pageSize = newSize
      if(isSearch)this.getData();
    },
    handleCurrentChange(newPage,isSearch=false) {
      this.queryParams.pageNum = newPage
      if(isSearch)this.getData();
    },
    // // 跳转到详情页
    // gotoDetail(strumInfo) {
    //   var accountInfoJson = JSON.stringify(strumInfo);
    //   this.$router.push({
    //     path: "/detail",
    //     query: {
    //       accountInfoJson,
    //     },
    //   });
    // },
  },
};
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
}
p{
  text-indent:0
}
.back-home{
  position: fixed;
  bottom: 20px;
  right: 30px;
  width: 60px;
  height: 60px;
  background-color: #2c2c2c66;
  border-radius: 30px;
  img{
    position: relative;
    top: 0px;
    transform: translateY(45%);
  }
  text-align: center;
}
.el-select {
  margin-right: 30px;
}
.ordination {
  float: left;
}
/* .text{
   height: 500px;
   width: 500px;
   background-color: #3598db;
} */

.prompt-msg {
  padding-top: 10px;
  margin-bottom: 20px;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
}
.eyeserch {
  width: 200px;
  float: left;
  background-color: #fbfbfb;
  position: absolute;
  right: 100px;
  top: 115px;
  padding-left: 30px;
}
a{
 text-decoration: none;
 }
/* button {
  border: 0; 去掉未选中边框

  outline: none; 去掉选中边框

  background-color: rgba(0, 0, 0, 0); 透明背景色
} */

.but {
  position: relative;
  margin-left: 20px;
  bottom: 9.5px;
  font-weight: bold;
  width: 80px;
  height: 50px;
}
.block1 {
  text-align: center;
  margin: 0 auto;
  margin-bottom: 30px;
}
.yqss-nr {
  // margin: 0 calc((100vw - 1190px)/2) 0 calc((100vw - 1190px)/2);
  overflow: hidden;
  margin-top: 20px;
}
.yqss-c1 {
  width: 1300px;
}
.yqss-c1 li {
  float: left;
  width: 279px;
  margin: 0 25px 30px 0;
}
.yqss-c1 li div {
  width: 275px;
  height: 275px;
  border: 2px solid #f0f0f0;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 3px;
  margin-bottom: 23px;
}
.yqss-c1 li div img {
  width: 275px;
  min-height: 275px;
}
.yqss-c1 li div i {
  width: 36px;
  height: 46px;
  display: block;
  position: absolute;
  top: -2px;
  right: 12px;
}
.yqss-c1 li h3 {
  font-size: 18px;
  margin-bottom: 20px;
}
.yqss-c1 li h3 a,
.yqss-c2 li h3 a {
  color: #666;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
}
.yqss-c1 li h3:hover a,
.yqss-c2 li h3:hover a {
  color: #68a3d8;
}
.yqss-c1 li b,
.yqss-c2 li b {
  font-weight: normal;
  font-size: 14px;
  color: #68a3d8;
  display: block;
  margin: 5px 0 5px 0;
}
.yqss-c1 li p {
  color: #666;
}
.yqss-c1 li p span,
.yqss-c2 li p span {
  display: inline-block;
  width: 58px;
}
.yqss-c1 li b,
.yqss-c1 li p {
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.ym {
  text-align: center;
  margin: 70px 0;
}

.yqss-c2 li {
  width: calc(100% - 64px);
  padding: 20px 30px;
  margin: 0 25px 30px 0;
  border: 2px solid #f0f0f0;
  position: relative;
  border-radius: 3px;
}
.yqss-c2 li i {
  width: 36px;
  height: 46px;
  display: block;
  position: absolute;
  top: -2px;
  right: 20px;
}
.yqss-c2 li div {
  overflow: hidden;
}
.yqss-c2 li div p {
  font-size: 14px;
  color: #666;
  float: left;
  width: 32%;
  margin-right: 1%;
}
.yqss-c2 li h3 {
  font-size: 18px;
  margin-bottom: 10px;
}
.yqss-tab {
  height: 50px;
  line-height: 50px;
  border: 1px solid #e5e5e5;
  background: #fbfbfb;
  // margin: 20px calc((100vw - 1190px)/2) 23px calc((100vw - 1190px)/2);
  margin-top: 20px;
}
.yqss-tab ul li.active {
  color: #ff6e47;
}
.yqss-tab ul {
  float: left;
}
.yqss-tab ul li.actiave {
  color: #ff6e47;
}
.yqss-tab li {
  text-align: center;
  float: left;
  color: #666;
  cursor: pointer;
  font-size: 14px;
  width: 138px;
  border-right: 1px solid #f2f2f2;
  position: relative;
}
.yqss-tab li:hover,
.yqss-tab .yqss-hover {
  color: #ff6e47;
  font-weight: bold;
}
.yqss-tab li i {
  display: block;
  cursor: pointer;
  position: absolute;
  right: 17px;
  top: 0;
  width: 16px;
  height: 100%;
}
.yqss-tab dl {
  float: right;
  cursor: pointer;
}
.input1 {
  float: left;
  width: 33%;
  margin-right: 20px;
}
.duoxuan {
  float: left;
}
.buttext {
  color: grey;
  position: relative;
  bottom: 4px;
  padding-right: 30px;
}
.buttext2 {
  // color: grey;
  position: relative;
  bottom: 1px;
  padding-left: 45px;
  padding-right: 20px;
}
.ssjg-l a {
  float: left;
  margin-left: 20px;
  display: block;
  font-weight: bold;
  color: #68a3d8;
  cursor: pointer;
}
.yqss-sx {
  background: #fbfbfb;
  // margin: 20px calc((100vw - 1190px)/2) 0 calc((100vw - 1190px)/2);
  margin-top: 20px;
  position: relative;
}
.yqss-sx-jg {
  overflow: hidden;
  padding: 0 16px;
  border: 1px solid #dedede;
  border-bottom: none;
}
.ssjg-l {
  overflow: hidden;
  font-size: 14px;
  margin: 18px 0 10px;
  line-height: 28px;
  float: left;
  width: 1035px;
}
.thingt {
  float: left;
  color: #333;
  font-weight: bold;
  margin: 0 0px 8px 0;
  width: 110px;
}

.ssjg-l span b {
  color: #659fed;
}
.ssjg-l p {
  float: left;
  border: 1px solid #68a3d8;
  height: 28px;
  margin: 0 8px 8px 0;
  padding: 0 6px;
  color: #68a3d8;
}
.ssjg-l p i {
  font-style: normal;
  max-width: 194px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  float: left;
}
.ssjg-l p a {
  color: #68a3d8;
  cursor: pointer;
  margin-left: 5px;
  font-size: 18px;
  float: right;
}
.ssjg-l b {
  float: left;
  color: #333;
  margin-right: 8px;
}
.ssjg-r {
  float: right;
  margin: 18px 0;
  line-height: 26px;
  font-size: 14px;
  color: #999;
  cursor: pointer;
}
.backGround {
  background: no-repeat top center url("../assets/img/yqss-01.png");
  background-size: 100%;
  width: 100%;
  height: 240px;
  line-height: 240px;
  overflow: hidden;
}
.backGround h3 {
  text-align: center;
  color: #fff;
  font-size: 58px;
  margin: 0 auto;
  text-shadow: 5px 5px 8px #167ec5;
}


.yqss-nav {
  background: #f6f6f6;
  height: 60px;
  line-height: 60px;
  padding-left: 350px;
}
.yqss-nav ul li {
  padding: 0 40px;
  float: left;
  font-size: 20px;
  list-style-type: none;
}
.yqss-nav ul li a {
  color: #999999;
  text-decoration: none;
}
.yqss-nav ul li:hover,
.yqss-nav ul li.yqss-nav-h {
  background: #fff;
  font-weight: bold;
}
.yqss-nav ul li:hover a,
.yqss-nav ul li.yqss-nav-h a {
  color: #68a3d8;
}
/* .head-l p{color:#3598DB; font-size:22px; font-family:"Arial","å¾®è½¯é›…é»‘"; margin:0 36px 0 44px}
.head-l span{color:#999; font-size:16px; display:block; position:relative; cursor:pointer} */
.center {
  margin: 0 auto;
  width: 1190px;
  height: 500px;
  position: relative;
  padding-bottom: 130px;
}
.yqss-tab p span {
  margin: 0 5px 0;
  color: #68a3d8;
  cursor: pointer;
}
</style>
