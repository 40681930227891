<template>
    <div class="progress">
        <span class="percent" :style="{background:backgroundColor}" v-for="(item,index) in activeArray" :key="index"></span>
    </div>
</template>
<script>
export default {
    props:{
        percent:Number,
        backgroundColor:String
    },
    data(){
        return{
            activeArray:[]
        }
    },
    created(){
        this.activeArray = Array.from(
            { length: this.percent/2 },
            (v, k) => k
        )
    },
}
</script>
<style lang="scss" scoped>
    .progress{
        width: 100%;
        text-align: left;
        height: 16px;
        border: 1px solid #2d2d2e;
    }
    .percent{
        display: inline-block;
        width: calc(2% - 1px);
        border-right: 1px solid #000113;
        height: 16px;
    }
</style>