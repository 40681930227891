import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import detail from '../views/detail.vue'
import screen from '../views/screen/screen.vue'
import index from '../views/index/index.vue'
import newsList from '../views/newsList.vue'
import newsDetail from '../views/newsDetail.vue'
import login from '../views/login.vue'



Vue.use(VueRouter)



const routes = [
  {
    path: '/',
    redirect: '/index'
  },
  {
    path: '/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/login',
    name: 'Login',
    component: login
  },
  {
    path: '/detail',
    name: 'detail',
    component: detail
  },
  {
    path: '/screen',
    name: 'screen',
    component: screen
  },
  {
    path: '/index',
    name: 'index',
    component: index
  },
  {
    path: '/newsList',
    name: 'newsList',
    component: newsList
  },
  {
    path: '/newsDetail',
    name: 'newsDetail',
    component: newsDetail
  }
]

const router = new VueRouter({
  // mode:'history',
  routes,
  scrollBehavior(to,from,savedPosition){
    return { x: 0, y: 0 }
  }
})

export default router
