<template>
  <div class="home">
    <div class="head jz">
      <header-logo></header-logo>
    </div>
    <div class="backGround">
      <h3>常州市大型科学仪器设备信息查询</h3>
    </div>
    <div class="yqss-nav">
      <ul class="jz">
        <li class="yqss-nav-h">
          <a href="#">科学仪器设备</a>
        </li>
      </ul>
    </div>

    <div style="width:1190px;margin:0 auto">
      <div class="img-box">
        <el-row>
          <el-col :span="8">
            <div class="swiper-slide">
              <img v-if="detaildata['pict']" :src="detaildata.pict" alt="" />
              <img v-else src="../assets/img/default.png" alt="" />
            </div>
          </el-col>
          <el-col :span="16">
            <h3
              style="
                font-size: 24px;
                color: #333;
                line-height: 68px;
                margin-bottom: 2px;
              "
            >
              {{ detaildata.name }}
            </h3>
            <div style="margin: 0 0 17px 0;">
              <div class="deteilimg">
                <img src="../assets/img/danwei.png" alt="" />
              </div>
              <div class="deteildan">所属企业</div>
              <div class="detaildann">{{ detaildata.dept_name }}</div>
            </div>
            <div style="margin: 0 0 17px 0;">
              <div class="deteilimg">
                <img src="../assets/img/xinhao.png" alt="" />
              </div>
              <div class="deteildan">型号</div>
              <div class="detaildann">{{ detaildata.type }}</div>
            </div>
            <div style="margin: 0 0 17px 0;">
              <div class="deteilimg">
                <img src="../assets/img/fenlei.png" alt="" />
              </div>
              <div class="deteildan">分类</div>
              <div class="detaildann">
                {{ detaildata.cify }}&gt; {{ detaildata.secondCifyName }}&gt;
                {{ detaildata.thirdCifyName }}
              </div>
            </div>
          </el-col>
        </el-row>
      </div>

      <div class="main-box">
        <el-row>
          <el-col :span="16">
            <div class="yqss-xq-c2">
              <div class="yqxq-c2-l">
                <ul class="yqxq-c2-l">
                  <li>
                    <h3 class="yqxq-t1">详细信息</h3>
                    <div><b>基本信息</b></div>
                    <el-row>
                      <el-col :span="12">
                        <label>原产国：</label>
                        <label>{{ detaildata.natiality }}</label>
                      </el-col>
                      <el-col :span="12">
                        <label>仪器厂商：</label>
                        <label>{{ detaildata.instrum_mer }}</label>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="12">
                        <label>启用日期：</label>
                        <label>{{ detaildata.c_date }}</label>
                      </el-col>
                      <el-col :span="12">
                        <label>参考收费标准：</label>
                        <label>{{ detaildata.r_rates }}</label>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="12">
                        <label>服务领域：</label>
                        <label>{{ detaildata.s_dom }}</label>
                      </el-col>
                      <el-col :span="12">
                        <!-- <label>预约服务网址：</label>
                        <label>{{ detaildata.r_serveurl }}</label> -->
                      </el-col>
                    </el-row>
                    <div><b>主要功能：</b></div>
                    <el-row>
                      <el-col :span="24">
                        <label v-html="encode(detaildata.instrum_use)"></label>
                      </el-col>
                    </el-row>
                    <div><b>技术指标：</b></div>
                    <el-row>
                      <el-col :span="24">
                        <label v-html="encode(detaildata.technical_index)"></label>
                      </el-col>
                    </el-row>
                  </li>
                  <li>
                    <h3 class="yqxq-t2" style="margin-top: 20px">服务信息</h3>
                    <div><b>服务内容：</b></div>
                    <el-row>
                      <el-col :span="24">
                        <label v-html="encode(detaildata.s_content)"></label>
                      </el-col>
                    </el-row>
                  </li>
                </ul>
              </div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="yqxq-c2-r">
              <h3 class="yqxq-c2-t">联系方式</h3>
              <div class="yqxq-c2-1">
                <p>安置地： {{ detaildata.put_address }}</p>
                <p>联系人： {{ detaildata.linkman }}</p>
                <p>联系电话： {{ detaildata.phone }}</p>
                <p>联系邮箱： {{ detaildata.e_address }}</p>
                <p>通讯地址： {{ detaildata.u_address }}</p>
                <p>邮政编码： {{ detaildata.p_code }}</p>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>

    <footer-index></footer-index>
  </div>
</template>

<script>
import headerLogo from './components/header.vue';
import footerIndex from './components/footer.vue';
import DevicePixelRatio from '../devicePixelRatio';
export default {
  components:{headerLogo,footerIndex},
  data() {
    return {
      id: "",
      detaildata: [],
    };
  },
  beforeCreate() {
    new DevicePixelRatio().init();
	},
  mounted() {
    this.$api.home.getDetail({ id: this.$route.query.id }).then((res) => {
      if(res.data){
        this.detaildata = res.data;
        if (this.detaildata.c_date.length > 10) {
          this.detaildata.c_date = this.detaildata.c_date.slice(0, 10) + "";
        }
        console.log(this.detaildata);
      }
    });
  },
  methods: {
    back(){
        this.$router.push('/index')
    },

    encode(data){
      if(!data)return;
      let string = decodeURIComponent(data);
      return string.replace(/&nbsp;/g,'')
    }
  },
};
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
}

p{
  text-indent:0
}

.img-box{
  width: 1190px;
  height: 400px;
  // margin: 20px calc((100vw - 1190px) / 2) 23px calc((100vw - 1190px) / 2);
  margin: 20px 0 23px 0;
}

.main-box{
  width: 1190px;
  // margin: 20px calc((100vw - 1190px) / 2) 23px calc((100vw - 1190px) / 2);
  margin: 20px 0 23px 0;
}
.deteildan {
  font-size: 20px;
  font-weight: normal;
}

.detaildann {
  font-size: 17px;
  color: #888888;
  margin-top: 5px;
  padding-left: 52px;
}
.deteilimg {
  float: left;
  margin: 2px 12px 0 0;
  width: 36px;
  img {
    // width: 90%;
    // height: 90%;
  }
}

table {
  border-collapse: collapse;
}
a {
  text-decoration: none;
}
.yqxq-c2-l {
  width: 780px;
  float: left;
  margin-bottom: 5px;
  .el-row {
    font-size: 18px;
    line-height: 2;
  }
}
.yqxq-c2-l li {
  list-style: none;
}
.yqxq-c2-l li h3 {
  display: inline-block;
  width: 737px;
  border: 2px solid #ededee;
  border-bottom: 2px solid #76a6d1;
  border-radius: 2px;
  background: #f9f9fb;
  height: 44px;
  line-height: 44px;
  padding-left: 40px;
  font-size: 16px;
  margin-bottom: 25px;
  margin-top: 4px;
}
.yqxq-c2-l ul li tr {
  line-height: 35px;
  font-size: 14px;
  border-bottom: 1px solid #76a6d1;
  text-align: left;
}
#lalala {
  border-bottom: 1px solid #ffffff;
}
.yqxq-c2-l li div {
  padding-left: 12px;
}
.yqxq-c2-l li div b {
  font-size: 16px;
  color: #68a3d8;
  line-height: 36px;
}
.yqxq-c2-l li div p {
  font-size: 18px;
  color: #333;
  line-height: 36px;
  margin-bottom: 36px;
}

.yqxq-c2-r {
  width: 357px;
  border: 2px solid #ededee;
  float: right;
}
.yqxq-c2-t {
  font-size: 16px;
  height: 46px;
  line-height: 46px;
  padding-left: 24px;
  background: #f9f9fb;
  color: #666;
  position: relative;
}

.yqxq-c2-1 {
  font-size: 18px;
  line-height: 36px;
  width: 90%;
  margin: 10px auto 30px;
}

.center {
  margin: 0 auto;
  width: 1190px;
  height: 500px;
}

.swiper-slide {
  width: 382px;
  height: 382px;
  margin-top: 20px;

  img {
    width: 382px;
    height: 382px;
  }
}

.yqss-nav {
  background: #f6f6f6;
  height: 60px;
  line-height: 60px;
  padding-left: 455px;

  ul li{
    padding: 0 40px;
    float: left;
    font-size: 20px;
    list-style-type: none;

    a{
      color: #999999;
      text-decoration: none;
    }
  }

  .yqss-nav-h {
    background: #fff;
    font-weight: bold;

    a {
      color: #68a3d8;
    }
  }
}

.backGround {
  background: no-repeat top center url("../assets/img/yqss-01.png");
  background-size: 100%;
  width: 100%;
  height: 240px;
  line-height: 240px;
  overflow: hidden;

  h3 {
    text-align: center;
    color: #fff;
    font-size: 58px;
    margin: 0 auto;
    text-shadow: 5px 5px 8px #167ec5;
  }
}
</style>
