export default {
    data() {
        return {
            //左下1 环形图
            option1: {
                tooltip: {
                    trigger: 'item',
                    backgroundColor: 'rgba(0,1,19,0.7)',
                    position: 'right',
                    textStyle: {
                        color: '#aaddff',
                        fontFamily: 'SimHei'
                    },
                    formatter: (params) => {
                        return `
                        <div style="padding:0 12px;text-align:left">
                            <span style='font-size:12px;'>${params.data.name}</span><br>
                            <span style="font-size:14px;color:#0BFBFB">${params.data.percent}</span>
                        </div>`;
                    },
                },
                legend: {
                    top: '50%',
                    type: 'scroll',
                    textStyle: {
                        color: '#aaddff',
                        fontFamily: 'SimHei'
                    },
                    data: []
                },
                color: ['#F9BF04', '#1ECA76', '#fd556c', '#c353db', '#12dda3'],
                series: [
                    {
                        type: 'pie',
                        radius: [10, 60],
                        center: ['50%', '25%'],
                        label: false,
                        data: []
                    }
                ]
            },
            //左下2 环形图
            option2: {
                tooltip: {
                    trigger: 'item',
                    backgroundColor: 'rgba(0,1,19,0.7)',
                    position: 'right',
                    textStyle: {
                        color: '#aaddff',
                        fontFamily: 'SimHei'
                    },
                    formatter: (params) => {
                        return `
                        <div style="padding:0 12px;text-align:left">
                            <span style='font-size:12px;'>${params.data.name}</span><br>
                            <span style="font-size:14px;color:#0BFBFB">${params.data.percent}</span>
                        </div>`;
                    },
                },
                legend: {
                    top: '50%',
                    textStyle: {
                        color: '#aaddff',
                        fontFamily: 'SimHei'
                    },
                    padding: 10,
                    data: [],
                    // formatter: function (name) {
                    //     if (name.length > 5) {
                    //         return name.slice(0, 5) + '\n' + name.slice(5);
                    //     } else {
                    //         return name
                    //     }
                    // }
                },
                // color: ['#0091FE', '#FD556C', '#63FE8F', '#F48F18', '#C354D8','#0099ff','#006af6','#b1de67'],
                series: [
                    {
                        type: 'pie',
                        radius: [10, 60],
                        center: ['50%', '25%'],
                        // roseType: 'area',
                        label: false,
                        // itemStyle: {
                        //     borderRadius: 8
                        // },
                        data: []
                    }
                ]
            },
            option3: {//左上柱状图 
                tooltip: {
                    trigger: 'item',
                    backgroundColor: 'rgba(0,1,19,0.7)',
                    position: 'right',
                    borderColor: '#243B68',
                    borderWidth: 1,
                    textStyle: {
                        color: '#aaddff',
                        fontFamily: 'SimHei'
                    },
                    formatter: (params) => {
                        return `
                        <div style="padding:0 12px;text-align:left">
                            <span style="font-size:12px;color:#0BFBFB">${params.name}</span><br>
                            <span style='font-size:12px;'>设备数量及占比：</span><br>
                            <span style="font-size:14px;color:#0BFBFB">${this.handleNumbers(params.data.value)}</span><br>
                            <span style="font-size:14px;color:#0BFBFB">${params.data.percent}</span>
                        </div>`;
                    },
                },
                legend: {
                    top: 'bottom',
                    textStyle: {
                        color: '#aaddff',
                        fontFamily: 'SimHei'
                    }
                },
                grid: {
                    top: '10%',
                    left: '25%',
                    height: '75%',
                    width: '65%',
                    bottom: '10%'
                },
                // color: ['#006AF6'],
                yAxis: {
                    type: 'category',
                    data: [],
                    axisLabel: {
                        show: true,
                        interval: 0,
                        // rotate: 40,
                        textStyle: {
                            color: '#aaddff',
                            fontFamily: 'SimHei'
                        },
                        formatter: function (params) {
                            var str = '';
                            // if (params.length <= 4) {
                            //     str = params.slice(0, 4)
                            // } else {
                            //     str = params.slice(0, 3) + '...'
                            // }
                            if(params.length <= 8){
                                str = params
                            }else{
                                str = params.slice(0,8) + '\n' + params.slice(8)
                            }
                            return str
                        }
                    },
                    axisLine: {
                        show: true,
                        lineStyle: {
                            type: 'solid',
                            color: '#2D3B5C',
                            width: '1'
                        }
                    }
                },
                xAxis: {
                    type: 'value',
                    minInterval: 1,
                    axisLine: {
                        show: true,
                        lineStyle: {
                            type: 'solid',
                            color: '#2D3B5C',
                            width: '1'
                        }
                    },
                    axisLabel: {
                        show: true,
                        textStyle: {
                            color: '#aaddff',
                            fontFamily: 'SimHei'
                        }
                    },
                    splitLine: {
                        show: false
                    },
                },
                series: [
                    {
                        data: [],
                        type: 'bar',
                        barWidth: '50%',
                        itemStyle:{
                            color:'#006AF6'
                        }
                        // label: {
                        //     show: true,
                        //     position: 'insideBottom',
                        //     color:'#aaddff',
                        //     formatter: (params)=>{
                        //         let arr = params.name.split('');
                        //         return arr.join('\n')
                        //     }
                        // },
                    }
                ]
            },
            //左上
            option4: {//左上柱状图 弹窗
                tooltip: {
                    trigger: 'item',
                    backgroundColor: 'rgba(0,1,19,0.7)',
                    position: 'right',
                    borderColor: '#243B68',
                    borderWidth: 1,
                    textStyle: {
                        color: '#aaddff',
                        fontFamily: 'SimHei'
                    },
                    formatter: (params) => {
                        return `
                        <div style="padding:0 12px;text-align:left">
                            <span style="font-size:12px;color:#0BFBFB">${params.name}</span><br>
                            <span style='font-size:12px;'>设备数量及占比：</span><br>
                            <span style="font-size:14px;color:#0BFBFB">${this.handleNumbers(params.data.value)}</span><br>
                            <span style="font-size:14px;color:#0BFBFB">${params.data.percent}</span>
                        </div>`;
                    },
                },
                legend: {
                    top: 'bottom',
                    textStyle: {
                        color: '#aaddff',
                        fontFamily: 'SimHei'
                    }
                },
                grid: {
                    top: '10%',
                    left: '5%',
                    height: '70%',
                    width: '90%',
                    // bottom: '10%'
                },
                // color: ['#006AF6'],
                xAxis: {
                    type: 'category',
                    data: [],
                    axisLabel: {
                        show: true,
                        interval: 0,
                        rotate: 40,
                        textStyle: {
                            color: '#aaddff',
                            fontFamily: 'SimHei'
                        },
                        formatter: function (params) {
                            var str = '';
                            // if (params.length <= 4) {
                            //     str = params.slice(0, 4)
                            // } else {
                            //     str = params.slice(0, 3) + '...'
                            // }
                            if(params.length <= 7){
                                str = params
                            }else{
                                str = params.slice(0,7) + '\n' + params.slice(7)
                            }
                            return str
                        }
                    },
                    axisLine: {
                        show: true,
                        lineStyle: {
                            type: 'solid',
                            color: '#2D3B5C',
                            width: '1'
                        }
                    }
                },
                yAxis: {
                    type: 'value',
                    minInterval: 1,
                    axisLine: {
                        show: true,
                        lineStyle: {
                            type: 'solid',
                            color: '#2D3B5C',
                            width: '1'
                        }
                    },
                    axisLabel: {
                        show: true,
                        textStyle: {
                            color: '#aaddff',
                            fontFamily: 'SimHei'
                        }
                    },
                    splitLine: {
                        show: false
                    },
                },
                series: [
                    {
                        data: [],
                        type: 'bar',
                        barWidth: '50%',
                        itemStyle:{
                            color:'#006AF6'
                        }
                        // label: {
                        //     show: true,
                        //     position: 'insideBottom',
                        //     color:'#aaddff',
                        //     formatter: (params)=>{
                        //         let arr = params.name.split('');
                        //         return arr.join('\n')
                        //     }
                        // },
                    }
                ]
            },
            //右上环形图
            option5: {
                tooltip: {
                    trigger: 'item',
                    backgroundColor: 'rgba(0,1,19,0.7)',
                    position: 'left',
                    textStyle: {
                        color: '#aaddff',
                        fontFamily: 'SimHei'
                    },
                    formatter: (params) => {
                        return `
                        <div style="padding:0 12px;text-align:left">
                            <span style='font-size:12px;'>${params.data.name}</span><br>
                            <span style="font-size:14px;color:#0BFBFB">设备数量：${params.data.value}</span>
                        </div>`;
                    },
                },
                legend: {
                    show:false,
                    type: 'scroll',
                    orient: 'vertical',
                    left: '55%',
                    // right:'10%',
                    top: 'center',
                    align: 'left',
                    textStyle: {
                        color: '#aaddff',
                        fontFamily: 'SimHei'
                    },
                    data: [],
                    pageIconSize:10,
                    pageTextStyle:{
                        color: '#aaddff',
                        fontFamily: 'arial'
                    },
                    formatter: function (name) {
                        if (name.length > 10) {
                            return name.slice(0, 10) + '\n' + name.slice(10);
                        } else {
                            return name
                        }
                    }
                },
                series: [
                    {
                        type: 'pie',
                        radius: [50, 85],
                        center: ['55%', '52%'],
                        // label: true,
                        data: [],
                        startAngle: 130,
                        label:{
                            show:true,
                            textStyle:{
                                color:'#aaddff'
                            },
                            // rotate:-10,
                            formatter:'{b}: {d}'
                        }
                    }
                ]
            },
            option6: {
                tooltip: {
                    trigger: 'item',
                    backgroundColor: 'rgba(0,1,19,0.7)',
                    position: 'left',
                    textStyle: {
                        color: '#aaddff',
                        fontFamily: 'SimHei'
                    },
                    formatter: (params) => {
                        return `
                        <div style="padding:0 12px;text-align:left">
                            <span style='font-size:12px;'>${params.data.name}</span><br>
                            <span style='font-size:12px;'>设备数量：<span style="color:#0BFBFB">${params.data.value}</span></span><br>
                            <span style="font-size:14px;color:#0BFBFB">${params.data.percent}</span>
                        </div>`;
                    },
                },
                // color: ['#4B12FF'],
                grid: {
                    // top: 22,
                    left: '10%',
                    height: '80%',
                    width: '80%',
                    bottom: '15%'
                },
                yAxis: {
                    type: 'category',
                    data: [],
                    axisLine: {
                        show: false,
                        lineStyle: {
                            color: '#aaddff'
                        }
                    },
                    axisTick: {
                        show: false
                    },
                    axisLabel: {
                        show: false
                    }
                },
                xAxis: {
                    type: 'value',
                    axisLine: {
                        show: false,
                        lineStyle: {
                            color: '#aaddff'
                        }
                    },
                    axisTick: {
                        show: false
                    },
                    axisLabel: {
                        show: false,
                    },
                    splitLine: {
                        show: false
                    },
                },
                series: [
                    {
                        data: [],
                        type: 'bar',
                        barCategoryGap: '35%',
                        label: {
                            show: true,
                            position: 'insideLeft',
                            color:'#aaddff',
                            formatter: '{b}'
                        },
                        itemStyle: { // 柱状图的背景色
                            normal: {
                                // 每个柱子的颜色即为colorList数组里的每一项,如果柱子数目多于colorList的长度，则柱子颜色循环使用该数组
                                color: function (params) {
                                    var index = params.dataIndex
                                    var colorList = [
                                        // 渐变颜色的色值和透明度
                                        // 透明度从0
                                        ['rgba(15,235,255,0.6)', 'rgba(15,235,255,0.6)', 'rgba(15,235,255,0.6)', 'rgba(15,235,255,0.6)', 'rgba(15,235,255,0.6)', 'rgba(15,235,255,0.6)', 'rgba(15,235,255,0.6)', 'rgba(13,94,208,0.6)', 'rgba(255,155,15,0.6)', 'rgba(253,103,96,0.6)'],
                                        ['rgba(15,235,255,0.3)', 'rgba(15,235,255,0.3)', 'rgba(15,235,255,0.3)', 'rgba(15,235,255,0.3)', 'rgba(15,235,255,0.3)', 'rgba(15,235,255,0.3)', 'rgba(15,235,255,0.3)', 'rgba(13,94,208,0.3)', 'rgba(255,155,15,0)', 'rgba(253,103,96,0.3)'] // 到透明度1 ,如果需要不同的颜色直接修改不同颜色即可
                                    ]
                                    return {
                                        colorStops: [{
                                            offset: 0.3, // 颜色的开始位置
                                            color: colorList[0][index] // 0% 处的颜色
                                        },
                                        {
                                            offset: 0.6, // 颜色的结束位置
                                            color: colorList[1][index] // 100% 处的颜色
                                        }]
                                    }
                                }
                            }
                        }
                    }
                ]
                // color: ['#FB8D1A', '#0398FA', '#5CBC58'],
                // graphic: [
                //     { //环形图中间添加文字
                //         type: 'text', //通过不同top值可以设置上下显示
                //         left: 'center',
                //         top: '44%',
                //         style: {
                //             text: '设备数量合计',
                //             textAlign: 'center',
                //             fill: '#aaddff', //文字的颜色
                //             width: 30,
                //             height: 30,
                //             fontSize: 12,
                //             fontFamily: 'SimHei'
                //         }
                //     },
                //     { //环形图中间添加文字
                //         type: 'text', //通过不同top值可以设置上下显示
                //         left: 'center',
                //         top: '53%',
                //         style: {
                //             text: '',
                //             textAlign: 'center',
                //             fill: '#0BFBFB', //文字的颜色
                //             width: 30,
                //             height: 30,
                //             fontSize: 14,
                //             fontFamily: 'SimHei'
                //         }
                //     },
                //     { //环形图中间添加文字
                //         type: 'text', //通过不同top值可以设置上下显示
                //         left: 'center',
                //         top: '63%',
                //         style: {
                //             text: '台',
                //             textAlign: 'center',
                //             fill: '#0BFBFB', //文字的颜色
                //             width: 30,
                //             height: 30,
                //             fontSize: 12,
                //             fontFamily: 'SimHei'
                //         }
                //     },
                // ],
                // series: [
                //     {
                //         type: 'pie',
                //         radius: [50, 70],
                //         center: ['50%', '50%'],
                //         label: false,
                //         data: []
                //     }
                // ]
            },
            //右下雷达图
            option7: {
                tooltip: {
                    trigger: 'item',
                    backgroundColor: 'rgba(0,1,19,0.7)',
                    position: 'left',
                    textStyle: {
                        color: '#aaddff',
                        fontFamily: 'SimHei'
                    },
                    // formatter: (params) => {
                    //     return `
                    //     <div style="padding:0 12px;text-align:left">
                    //         <span style='font-size:12px;'>${params.data.name}</span><br>
                    //         <span style="font-size:14px;color:#0BFBFB">${params.data.value}</span><br>
                    //         <span style="font-size:14px;color:#0BFBFB">${params.data.percent}</span>
                    //     </div>`;
                    // },
                },
                radar: {
                    // shape: 'circle',
                    center: ['50%', '50%'],
                    indicator: [],
                    axisName: {
                        color: '#aaddff',
                        fontFamily: 'SimHei'
                    },
                    nameGap: 5,
                    splitArea: { // 坐标轴在 grid 区域中的分隔区域，默认不显示。
                        show: true,
                        areaStyle: { // 分隔区域的样式设置。
                            color: ['rgba(4,15,51,0.3)', 'rgba(255,255,255,0.3)'],
                            // 分隔区域颜色。分隔区域会按数组中颜色的顺序依次循环设置颜色。默认是一个深浅的间隔色。
                        }
                    },
                },
                series: [
                    {
                        type: 'radar',
                        data: []
                    }
                ]
            },
            //新增热点
            //企业
            option8: {
                tooltip: {
                    trigger: 'item',
                    backgroundColor: 'rgba(0,1,19,0.7)',
                    position: 'left',
                    textStyle: {
                        color: '#aaddff',
                        fontFamily: 'SimHei'
                    },
                    formatter: (params) => {
                        if (params.name) {
                            return `
                            <div style="padding:0 12px;text-align:left">
                                <span style='font-size:12px;'>${params.name}</span><br>
                                <span style="font-size:14px;color:#0BFBFB">访问次数：${params.value}</span>
                            </div>`;
                        }
                    },
                },
                legend: {
                    type: 'scroll',
                    orient: 'vertical',
                    left: '55%',
                    // right:'10%',
                    top: 'center',
                    align: 'left',
                    textStyle: {
                        color: '#aaddff',
                        fontFamily: 'SimHei'
                    },
                    data: [],
                    pageIconSize:10,
                    pageTextStyle:{
                        color: '#aaddff',
                        fontFamily: 'arial'
                    },
                    formatter: function (name) {
                        if (name.length > 10) {
                            return name.slice(0, 10) + '\n' + name.slice(10);
                        } else {
                            return name
                        }
                    }
                },
                series: [
                    {
                        type: 'pie',
                        radius: [65, 72],
                        center: ['30%', '50%'],
                        startAngle: 360,
                        label: false,
                        animation: false,
                        data: [
                            {
                                value: '', //需要显示的数据
                                name: '',
                                itemStyle: {
                                    normal: {
                                        color: '#0091FE'
                                    }
                                }
                            },
                            {
                                value: '',
                                //不需要显示的数据，颜色设置成和背景一样
                                itemStyle: {
                                    normal: {
                                        color: 'transparent'
                                    }
                                }
                            }
                        ]
                    },
                    {
                        type: 'pie',
                        radius: [53, 60],
                        center: ['30%', '50%'],
                        startAngle: 345,
                        label: false,
                        animation: false,
                        data: [
                            {
                                value: '', //需要显示的数据
                                name: '',
                                itemStyle: {
                                    normal: {
                                        color: '#F48F18'
                                    }
                                }
                            },
                            {
                                value: '',
                                //不需要显示的数据，颜色设置成和背景一样
                                itemStyle: {
                                    normal: {
                                        color: 'transparent'
                                    }
                                }
                            }
                        ]
                    },
                    {
                        type: 'pie',
                        radius: [41, 48],
                        center: ['30%', '50%'],
                        startAngle: 330,
                        label: false,
                        animation: false,
                        data: [
                            {
                                value: '', //需要显示的数据
                                name: '',
                                itemStyle: {
                                    normal: {
                                        color: '#63FE8F'
                                    }
                                }
                            },
                            {
                                value: '',
                                //不需要显示的数据，颜色设置成和背景一样
                                itemStyle: {
                                    normal: {
                                        color: 'transparent'
                                    }
                                }
                            }
                        ]
                    },
                    {
                        type: 'pie',
                        radius: [29, 36],
                        center: ['30%', '50%'],
                        startAngle: 315,
                        label: false,
                        animation: false,
                        data: [
                            {
                                value: '', //需要显示的数据
                                name: '',
                                itemStyle: {
                                    normal: {
                                        color: '#e46942'
                                    }
                                }
                            },
                            {
                                value: '',
                                //不需要显示的数据，颜色设置成和背景一样
                                itemStyle: {
                                    normal: {
                                        color: 'transparent'
                                    }
                                }
                            }
                        ]
                    },
                    {
                        type: 'pie',
                        radius: [17, 24],
                        center: ['30%', '50%'],
                        startAngle: 295,
                        label: false,
                        animation: false,
                        data: [
                            {
                                value: '', //需要显示的数据
                                name: '',
                                itemStyle: {
                                    normal: {
                                        color: '#006af6'
                                    }
                                }
                            },
                            {
                                value: '',
                                //不需要显示的数据，颜色设置成和背景一样
                                itemStyle: {
                                    normal: {
                                        color: 'transparent'
                                    }
                                }
                            }
                        ]
                    },
                ]
            },
            //设备
            option9: {
                tooltip: {
                    trigger: 'item',
                    backgroundColor: 'rgba(0,1,19,0.7)',
                    position: 'left',
                    textStyle: {
                        color: '#aaddff',
                        fontFamily: 'SimHei'
                    },
                    formatter: (params) => {
                        if (params.name) {
                            return `
                            <div style="padding:0 12px;text-align:left">
                                <span style='font-size:12px;'>${params.name}</span><br>
                                <span style="font-size:14px;color:#0BFBFB">访问次数：${params.value}</span>
                            </div>`;
                        }
                    },
                },
                legend: {
                    type:'scroll',
                    orient: 'vertical',
                    left: '55%',
                    // right:'10%',
                    top: 'center',
                    align: 'left',
                    textStyle: {
                        color: '#aaddff',
                        fontFamily: 'SimHei'
                    },
                    data: [],
                    pageIconSize:10,
                    pageTextStyle:{
                        color: '#aaddff',
                        fontFamily: 'arial'
                    },
                    formatter: function (name) {
                        if (name.length > 10) {
                            return name.slice(0, 10) + '\n' + name.slice(10);
                        } else {
                            return name
                        }
                    }
                },
                color: ['#996699', '#aaddffF99', '#CCCCFF', '#9999CC', '#FF99CC'],
                series: [
                    {
                        type: 'pie',
                        radius: [65, 72],
                        center: ['30%', '50%'],
                        startAngle: 90,
                        label: false,
                        animation: false,
                        data: [
                            {
                                value: '', //需要显示的数据
                                // name: '通过率'+pass_rate+'%',
                                itemStyle: {
                                    normal: {
                                        color: '#996699'
                                    }
                                }
                            },
                            {
                                value: '',
                                //不需要显示的数据，颜色设置成和背景一样
                                itemStyle: {
                                    normal: {
                                        color: 'transparent'
                                    }
                                }
                            }
                        ]
                    },
                    {
                        type: 'pie',
                        radius: [53, 60],
                        center: ['30%', '50%'],
                        startAngle: 75,
                        label: false,
                        animation: false,
                        data: [
                            {
                                value: '', //需要显示的数据
                                name: '',
                                itemStyle: {
                                    normal: {
                                        color: '#FFFF99'
                                    }
                                }
                            },
                            {
                                value: '',
                                //不需要显示的数据，颜色设置成和背景一样
                                itemStyle: {
                                    normal: {
                                        color: 'transparent'
                                    }
                                }
                            }
                        ]
                    },
                    {
                        type: 'pie',
                        radius: [41, 48],
                        center: ['30%', '50%'],
                        startAngle: 50,
                        label: false,
                        animation: false,
                        data: [
                            {
                                value: '', //需要显示的数据
                                name: '',
                                itemStyle: {
                                    normal: {
                                        color: '#CCCCFF'
                                    }
                                }
                            },
                            {
                                value: '',
                                //不需要显示的数据，颜色设置成和背景一样
                                itemStyle: {
                                    normal: {
                                        color: 'transparent'
                                    }
                                }
                            }
                        ]
                    },
                    {
                        type: 'pie',
                        radius: [29, 36],
                        center: ['30%', '50%'],
                        startAngle: 35,
                        label: false,
                        animation: false,
                        data: [
                            {
                                value: '', //需要显示的数据
                                name: '',
                                itemStyle: {
                                    normal: {
                                        color: '#9999CC'
                                    }
                                }
                            },
                            {
                                value: '',
                                // 不需要显示的数据，颜色设置成和背景一样
                                itemStyle: {
                                    normal: {
                                        color: 'transparent'
                                    }
                                }
                            }
                        ]
                    },
                    {
                        type: 'pie',
                        radius: [17, 24],
                        center: ['30%', '50%'],
                        startAngle: 20,
                        label: false,
                        animation: false,
                        data: [
                            {
                                value: '', //需要显示的数据
                                name: '',
                                itemStyle: {
                                    normal: {
                                        color: '#FF99CC'
                                    }
                                }
                            },
                            {
                                value: '',
                                //不需要显示的数据，颜色设置成和背景一样
                                itemStyle: {
                                    normal: {
                                        color: 'transparent'
                                    }
                                }
                            }
                        ]
                    },
                ]
            },
        }
    },
}
