import axios from 'axios'
import { Message, Loading } from 'element-ui'
import { get, isEmpty } from 'lodash'
import router from '../router'
// import store from '../store'
import qs from 'qs'
import Cookies from 'js-cookie'
import { domain_ip, tokenTest, useridTest } from './static-url'
import {getToken} from "@/api/auth";

// 全局loading实例
let _loadingInstance

// 是否有正在刷新tokne的标记
let _isRefTokening = false

// 当token失效时，把所有请求放入此重试队列，每一项将是一个待执行的函数形式
let _requests = []

/**
 * @description 创建请求实例
 */
function createService() {
	// 创建一个 axios 实例
	const service = axios.create()

	// 给实例添加一个setToken方法，用于登录后将最新token动态添加到header，同时将token保存在localStorage中
	service.setToken = token => {
		service.defaults.headers['Authorization'] = token
	}

	// 请求拦截
	service.interceptors.request.use(
		config => config,
		error => {
			// 发送失败
			console.log(error)
			return Promise.reject(error)
		}
	)
	// 响应拦截
	service.interceptors.response.use(
		response => {
			const res = response.data
			const isLogout = response.request.responseURL.indexOf('auth/logout');
			// console.log('requestrequestrequestrequest',response.data);
			if (!isLogout && res.type!=='1' && res.code!==200) {
				//判断是否存在loading状态 存在则关闭
				loadingClose()
				Message({
					message: res.message || '错误',
					type: 'error',
					duration: 5 * 1000,
				})
				return Promise.reject(res)
			} else {
				//导出文件类型，返回字符流
				if (response.headers['content-type'].includes('application/octet-stream')) {
					return res
				}
				//判断是否存在loading状态 存在则关闭
				loadingClose()
				return res
			}
		},
		error => {
			const status = get(error, 'response.status')
			switch (status) {
				case 400:
					error.message = '请求错误'
					break
				case 401:
					error.message = '未授权，请登录'
					break
				case 403:
					error.message = '拒绝访问'
					break
				case 404:
					error.message = `请求地址出错: ${error.response.config.url}`
					break
				case 408:
					error.message = '请求超时'
					break
				case 500:
					error.message = '服务器内部错误'
					break
				case 501:
					error.message = '服务未实现'
					break
				case 502:
					error.message = '网关错误'
					break
				case 503:
					error.message = '服务不可用'
					break
				case 504:
					error.message = '网关超时'
					break
				case 505:
					error.message = 'HTTP版本不受支持'
					break
				default:
					break
			}
			// 打印到控制台
			if (process.env.NODE_ENV === 'development') {
				console.log('>>>>>> 接口错误： >>>>>>')
				console.log(error)
			}
			//判断是否存在loading状态 存在则关闭
			loadingClose()
			// 显示提示
			Message({
				message: error.message,
				type: 'error',
				duration: 5 * 1000,
			})
			throw error
		}
	)
	return service
}

function stringify(data) {
	return qs.stringify(data, { allowDots: true, encode: false })
}

//弹出loadingl
function loadingOpen(isLoading) {
	if (isLoading) {
		_loadingInstance = Loading.service({
			lock: true,
			text: '加载中……',
			background: 'rgba(0, 0, 0, 0.7)',
		})
	}
}

//关闭loading
function loadingClose() {
	if (_loadingInstance) {
		setTimeout(() => {
			_loadingInstance.close()
		}, 300)
	}
}

/**
 * @description 创建请求方法
 * @param {Object} service axios 实例
 */
function createRequest(service) {
	return function({ isLoding = false, domain = '', ...config }) {
		// loadingOpen(isLoding) // isLoding：判断是否显示loading状态
		var au='';
		var userid=getToken() || useridTest;
		var domain=Cookies.get('domain')?Cookies.get('domain'):'';
		var auJson=Cookies.get('_store');
		if(auJson){
			var decocodeStr = decodeURIComponent(decodeURI(auJson));
			//console.log("bearTokenData:", decocodeStr);
			var jsonCookie = JSON.parse(decocodeStr);
			au = "Bearer " + jsonCookie.jwtToken;
		}
		else{
			au=tokenTest;
		}
		const configDefault = {
			headers: {
				Authorization: get(config, 'headers.Authorization', au),
				UserId: get(config, 'headers.UserId', userid),
				Domain: get(config, 'headers.Domain', domain),
				'Content-Type': get(config, 'headers.Content-Type', 'application/json;charset=UTF-8'),
			},
			method: 'POST',
			timeout: 30 * 1000,
			baseURL: domain_ip, //选择相应的域名
			data: {},
		}
		const option = Object.assign(configDefault, config)

		// 处理 get 请求的参数
		if (option.method.toLowerCase() === 'get') {
			const _params = option.params || option.data
			if (_params && JSON.stringify(_params) !== '{}') {
				option.url = option.url + '?' + stringify(_params)
				option.params = {}
				option.data = {}
			}
		}

		// 当需要以 form 形式发送时 处理发送的数据
		if (!isEmpty(option.data) && option.headers['Content-Type'].includes('application/x-www-form-urlencoded')) {
			option.data = stringify(option.data)
		}

		// 当需要以 formData 形式发送时 处理发送的数据
		if (!isEmpty(option.data) && option.headers['Content-Type'].includes('multipart/form-data;charset=UTF-8')) {
			let params = option.data
			let formData = new FormData()
			if (Object.prototype.toString.call(params) === '[object Object]') {
				for (let key in params) {
					formData.append(key, params[key])
				}
			}
			option.data = formData
		}
		return service(option)
	}
}

export const service = createService()
export const request = createRequest(service)
