import { request } from "../request";

export default {
    // 获取列表
    getList(data) {
        return request({
            data,
            method: "POST",
            isLoding: true,
            url: "/trum/list"
        });
    },
    // 获取地区
    getRegion(data) {
        return request({
            data,
            method: "POST",
            isLoding: true,
            url: "/trum/deviceSum"
        });
    },
     // 一级、二级仪器编码
     getEncoding(data) {
        return request({
            data,
            method: "POST",
            isLoding: true,
            url: "/trum/instrumType"
        });
    },
    //三级级仪器编码
    getEncodingthree(data) {
        return request({
            data,
            method: "POST",
            isLoding: true,
            url: "/trum/instrumThirdType"
        });
    },
       // 详请请
       getDetail(data) {
        return request({
            data,
            method: "POST",
            isLoding: true,
            url: "/trum/instrumDetails"
        });
    }
    
}
