import { request } from "../request";

export default {
    //标签
    newsType(){
        return request({
            method: "POST",
            isLoding: true,
            url: "/dynamic/newsType"
        });
    },
    //查询列表
    getList(data){
        return request({
            data,
            method: "POST",
            isLoding: true,
            url: "/dynamic/list"
        });
    },
    //右侧列表
    getRightList(){
        return request({
            method: "POST",
            isLoding: true,
            url: "/dynamic/rightList"
        });
    },
    //详情
    getDetail(id){
        return request({
            method: "GET",
            isLoding: true,
            url: "/dynamic/deatil/" + id
        });
    },
    //首页汇总信息
    summary(){
        return request({
            method: "POST",
            isLoding: true,
            url: "/dynamic/summary"
        });
    }
}