/**
 * 为了扩展性 微服务域名统一管理
 * 微服务所有测试域名
 **/
// const dev_ip = 'http://192.168.1.233/er000' //测试环境
const dev_ip = '/' //模拟调用发布的后端接口 http://192.168.1.233
const pro_ip = '/' //生产环境
//根据环境判定返回相应的域名
export const domain_ip = process.env.NODE_ENV === 'production' ? pro_ip : dev_ip
// officeWeb365附件在线预览地址（注：完整地址officeWeburl+fileUrl+filePath）(PS:暂无使用)
export const officeWeburl = process.env.NODE_ENV === 'production' ? window.location.protocol+'//'+window.location.hostname + ':8088/?furl=' :'http://8.136.141.198:8088/?furl='
// 附件地址(PS:暂无使用)
export const fileUrl = process.env.NODE_ENV === 'production' ? window.location.protocol+'//'+window.location.hostname + ':80/fileupload' : 'http://8.136.141.198:80/fileupload'
//测试userid
export const useridTest = '60d7f762-19fd-43c9-8aa2-fbebd7549cbf'
//测试token
export const tokenTest = 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyaWQiOiI2MGQ3Zjc2Mi0xOWZkLTQzYzktOGFhMi1mYmViZDc1NDljYmYiLCJ1c2VybmFtZSI6ImFkbWluIiwiZGVwYXJ0bWVudGlkIjoiMEIxRjlGMjktRUFCOC00MzIyLTk1QzItODdCQTlDMUJGMEJGIiwiZGVwYXJ0bWVudG5hbWUiOiLmn6DmnaHloZTlhazlj7giLCJkb21haW4iOiIwMDUiLCJsb2dpbmlkZW50aXR5IjoiZmIxYmYyNWEtYWUyZi00YTdmLTgzNjctNTI1NGRhYzUwNGI1IiwiZXhwIjoxNjM0NjEwOTE0LCJpc3MiOiJ3d3cuY2FyaS5jb20uY24iLCJhdWQiOiJpYnAifQ.X7XqDq-nPcGgNkx72xrSrSDa6QM8kjLS36hhybljHoI'

