import Vue from 'vue'
import App from './App.vue'
import router from './router'

import './plugins/element.js'
// import axios from 'axios'
import ElementUI from 'element-ui';
import Pagination from "pagination";
import 'element-ui/lib/theme-chalk/index.css';
import ECharts from '../components/ECharts.vue';
import echarts from "echarts";
import './styles/transition.scss'; // 全局css
import themeLight from '@/assets/echarts-theme/light.json';
import 'echarts-gl';
import api from './api';
import animated from 'animate.css';
import modal from './api/modules/modal'
import './permission' // permission control
// import * as swiperAni from '../src/static/swiper.animate1.0.3.min.js'
import vueAwesomeSwiper from 'vue-awesome-swiper'
// 省略部分代码
Vue.use(animated)
Vue.use(vueAwesomeSwiper)
Vue.prototype.$api = api //请求接口

require('./assets/scss/common.scss')
//引入所有表
require("echarts");
Vue.component('v-chart', ECharts)
ECharts.registerTheme('light', themeLight)
Vue.prototype.$echarts = echarts;
Vue.component('Pagination', Pagination)
Vue.use(ElementUI);
Vue.prototype.$modal = modal
// Vue.prototype.axios = axios;

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')

// Vue.prototype.$swiperAnimateCache = swiperAni.swiperAnimateCache;
// Vue.prototype.$swiperAnimate = swiperAni.swiperAnimate;
// Vue.prototype.$clearSwiperAnimate = swiperAni.clearSwiperAnimate;


// axios({
//   url:'http://192.168.1.49:9201/dept/list',
//   method:'get'
// }).then(res=>{
//   console.log(res)
// })
// axios.interceptors.request.use(
//   config => {
//     if (localStorage.JWT_TOKEN) {  // 判断是否存在token，如果存在的话，则每个http header都加上token
//       config.headers.Authorization = `token ${localStorage.JWT_TOKEN}`;
//     }
//     return config;
//   },
//   err => {
//     return Promise.reject(err);
//   });
